// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
  /* display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 50vw; */

  width: 100%;
}

.logo-container {
  width: 28rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1440px) {
  .logo-container {
    width: 20rem;
  }
}

@media (max-width: 1000px) {
  .logo-container {
    width: 14rem;
  }
}

#main-text {
  font-size: 3rem;
  letter-spacing: 1.5rem;
}

#subtext {
  font-size: 1.25rem;
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
}

#subtext-container {
  display: flex;
  height: 2.75rem;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}

p {
  display: block;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Logo.css"],"names":[],"mappings":"AAAA;EACE;;;oBAGkB;;EAElB,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,eAAe;EACf,sBAAsB;EACtB,uBAAuB;EACvB,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,mBAAmB;EACnB,wBAAwB;EACxB,sBAAsB;AACxB","sourcesContent":[".logo {\r\n  /* display: flex;\r\n  align-items: center;\r\n  flex-direction: column;\r\n  max-width: 50vw; */\r\n\r\n  width: 100%;\r\n}\r\n\r\n.logo-container {\r\n  width: 28rem;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n}\r\n\r\n@media (max-width: 1440px) {\r\n  .logo-container {\r\n    width: 20rem;\r\n  }\r\n}\r\n\r\n@media (max-width: 1000px) {\r\n  .logo-container {\r\n    width: 14rem;\r\n  }\r\n}\r\n\r\n#main-text {\r\n  font-size: 3rem;\r\n  letter-spacing: 1.5rem;\r\n}\r\n\r\n#subtext {\r\n  font-size: 1.25rem;\r\n  letter-spacing: 0.0625rem;\r\n  text-transform: uppercase;\r\n}\r\n\r\n#subtext-container {\r\n  display: flex;\r\n  height: 2.75rem;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  flex-shrink: 0;\r\n}\r\n\r\np {\r\n  display: block;\r\n  margin-block-start: 0;\r\n  margin-block-end: 0;\r\n  margin-inline-start: 0px;\r\n  margin-inline-end: 0px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
