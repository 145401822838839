// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  gap: 4rem;
  height: 40vh;
  width: 100%;
  background-position: center;
}

.banner-text {
  color: white;
  font-family: "WMFD-Bold";
  text-align: center;
  font-size: 8rem;
  text-transform: lowercase;
  line-height: 8rem;
}

.banner-subtext {
  color: white;
  font-family: "WMFD-SemiBold";
  text-align: center;
  font-size: 3rem;
  text-transform: lowercase;
}

@media (max-width: 768px) {
  .banner {
    height: 40vh;
    gap: 1rem;
  }
  .banner-text {
    font-size: 4.5rem;
    line-height: 5rem;
  }
  .banner-subtext {
    font-size: 2rem;
  }
}

@media (max-width: 425px) {
  .banner {
    height: 30vh;
    gap: 0rem;
  }
  .banner-text {
    font-size: 3rem;
  }
  .banner-subtext {
    font-size: 1rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Banners.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,SAAS;EACT,YAAY;EACZ,WAAW;EACX,2BAA2B;AAC7B;;AAEA;EACE,YAAY;EACZ,wBAAwB;EACxB,kBAAkB;EAClB,eAAe;EACf,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,4BAA4B;EAC5B,kBAAkB;EAClB,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE;IACE,YAAY;IACZ,SAAS;EACX;EACA;IACE,iBAAiB;IACjB,iBAAiB;EACnB;EACA;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,YAAY;IACZ,SAAS;EACX;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;AACF","sourcesContent":[".banner {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  justify-content: center;\r\n  background-size: cover;\r\n  gap: 4rem;\r\n  height: 40vh;\r\n  width: 100%;\r\n  background-position: center;\r\n}\r\n\r\n.banner-text {\r\n  color: white;\r\n  font-family: \"WMFD-Bold\";\r\n  text-align: center;\r\n  font-size: 8rem;\r\n  text-transform: lowercase;\r\n  line-height: 8rem;\r\n}\r\n\r\n.banner-subtext {\r\n  color: white;\r\n  font-family: \"WMFD-SemiBold\";\r\n  text-align: center;\r\n  font-size: 3rem;\r\n  text-transform: lowercase;\r\n}\r\n\r\n@media (max-width: 768px) {\r\n  .banner {\r\n    height: 40vh;\r\n    gap: 1rem;\r\n  }\r\n  .banner-text {\r\n    font-size: 4.5rem;\r\n    line-height: 5rem;\r\n  }\r\n  .banner-subtext {\r\n    font-size: 2rem;\r\n  }\r\n}\r\n\r\n@media (max-width: 425px) {\r\n  .banner {\r\n    height: 30vh;\r\n    gap: 0rem;\r\n  }\r\n  .banner-text {\r\n    font-size: 3rem;\r\n  }\r\n  .banner-subtext {\r\n    font-size: 1rem;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
