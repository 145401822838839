import "./SundaySchool.css";
import React from "react";

import { useTranslation, Trans } from "react-i18next";

const infoEng = {
  ages: "3 years old to Year 6",
  where: "Beverly Hills Chinese Baptist Church, 192 Stoney Creek Road, Beverly Hills NSW 2209",
  "session 1": "Sunday, 9:00am to 10:30am (Mandarin/English Service)",
  "session 2": "Sunday, 11:00am to 12:30pm (Cantonese Service)",
};

const infoTrad = {
  年齡: "三歲至小學六年級",
  地點: "比華利山華人浸信會, 192 Stoney Creek Road, Beverly Hills NSW 2209",
  第1節: "主日， 9:00am 至 10:30am (國語/英語崇拜時段)",
  第2節: "主日， 11:00am 至 12:30pm (粵語崇拜)",
};

const infoSimp = {
  年龄: "三岁至小学六年级",
  地点: "比华利山华人浸信会, 192 Stoney Creek Road, Beverly Hills NSW 2209",
  第1节: "主日， 9:00am 至 10:30am (国语/英语崇拜时段)",
  第2节: "主日， 11:00am 至 12:30pm (粤语崇拜)",
};

export default function SundaySchool() {
  const { t, i18n } = useTranslation();
  let info = {};

  const [isABannerOpen, setABannerOpen] = React.useState(false);
  const [isCBannerOpen, setCBannerOpen] = React.useState(false);

  if (window.localStorage.getItem("i18nextLng").includes("en")) {
    info = infoEng;
  } else if (window.localStorage.getItem("i18nextLng").includes("trad")) {
    info = infoTrad;
  } else if (window.localStorage.getItem("i18nextLng").includes("simp")) {
    info = infoSimp;
  }

  React.useEffect(() => {
    const section = document.querySelector("#childrens-sunday-school");

    if (isCBannerOpen) {
      section.scrollIntoView();
    }
  }, [isCBannerOpen]);

  React.useEffect(() => {
    const section = document.querySelector("#adults-sunday-school");

    if (isABannerOpen) {
      section.scrollIntoView();
    }
  }, [isABannerOpen]);

  const [isMobile, setMobile] = React.useState(window.innerWidth <= 650);

  const updateMedia = () => {
    setMobile(window.innerWidth <= 650);
  };

  React.useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  // const Page = () => {
  //   return <>{isMobile ? PageMobile() : PageDesktop()}</>;
  // };

  const Table = () => {
    return <div>{isMobile ? InfoTableMobile() : InfoTable()}</div>;
  };

  function InfoTable() {
    const map = Object.keys(info).map((title, i) => {
      return (
        <tr className="table-tr">
          <td className="table-td table-td-bold" style={{ borderRight: "1px solid #b9b9b9" }}>
            {title}
          </td>
          <td style={{ padding: "1rem", flex: 5 }}>{info[title]}</td>
        </tr>
      );
    });

    return map;
  }

  function InfoTableMobile() {
    const map = Object.keys(info).map((title, i) => {
      return (
        <>
          <tr className="table-td table-td-bold" style={{ borderBottom: "0px" }}>
            {title}
          </tr>
          <tr className="table-td" style={{ borderBottom: "1px solid lightgrey" }}>
            {info[title]}
          </tr>
        </>
      );
    });

    return map;
  }

  function SundaySchoolTable() {
    if (window.localStorage.getItem("i18nextLng").includes("trad")) {
      return <img className="image-default-large" src="/images/sunday_school_table_trad.png"></img>;
    } else if (window.localStorage.getItem("i18nextLng").includes("simp")) {
      return <img className="image-default-large" src="/images/sunday_school_table_simp.png"></img>;
    } else {
      return <img className="image-default-large" src="/images/sunday_school_table_english.png"></img>;
    }
  }

  // function PageMobile() {
  //   return (
  //     <>
  //       <div className="section">
  //         <h3>
  //           Sunday School is where the people of Beverly Hills Chinese Baptist Church come to know God’s word. We currently hold Sunday School for adults in Mandarin and Cantonese, and children in
  //           English. <br />
  //           <br />
  //           See below for more details.
  //         </h3>
  //       </div>
  //       <div
  //         className="sunday-school-banner"
  //         onClick={() => {
  //           setABannerOpen(!isABannerOpen);
  //         }}
  //         style={{ backgroundPosition: "50% 30%", backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/images/banners/sunday_school_banner.jpg)" }}
  //       >
  //         <p className="banner-text">adult's</p>
  //         <p className="banner-subtext">sunday school</p>
  //         {isABannerOpen ? (
  //           <span style={{ fontSize: "5rem", color: "white" }} class="material-symbols-outlined sunday-school-expand-less">
  //             expand_less
  //           </span>
  //         ) : (
  //           <span style={{ fontSize: "5rem", color: "white" }} class="material-symbols-outlined sunday-school-expand-less">
  //             expand_more
  //           </span>
  //         )}
  //       </div>
  //       {isABannerOpen && AdultsSundaySchool()}
  //       <div
  //         className="sunday-school-banner"
  //         onClick={() => {
  //           setCBannerOpen(!isCBannerOpen);
  //         }}
  //         style={{ backgroundPosition: "50% 40%", backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/images/banners/childrens_sunday_school_banner.jpg)" }}
  //       >
  //         <p className="banner-text">children's</p>
  //         <p className="banner-subtext">sunday school</p>
  //         {isCBannerOpen ? (
  //           <span style={{ fontSize: "5rem", color: "white" }} class="material-symbols-outlined sunday-school-expand-less">
  //             expand_less
  //           </span>
  //         ) : (
  //           <span style={{ fontSize: "5rem", color: "white" }} class="material-symbols-outlined sunday-school-expand-less">
  //             expand_more
  //           </span>
  //         )}
  //       </div>
  //       {isCBannerOpen && ChildrensSundaySchool()}
  //     </>
  //   );
  // }

  function ChildrensSundaySchool() {
    return (
      <>
        <div id="childrens-sunday-school" className="section">
          <h1>{t("ss.c_heading")}</h1>
          <h3 style={{ textAlign: "center" }}>
            <Trans i18nKey="ss.c_quote">
              <span style={{ fontFamily: "WMFD-Bold" }}></span>
            </Trans>
          </h3>
        </div>
        <div className="section" style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "2rem" }}>
          <h1 style={{ width: "100%" }}></h1>
          <div className="info-tables">
            <table
              className="church-history-table"
              style={{
                borderRadius: "0.5rem",
                borderTop: "1px solid #B9B9B9",
                borderLeft: "1px solid #B9B9B9",
                borderRight: "1px solid #B9B9B9",
                fontFamily: "WMFT-Regular",
                fontSize: "1.25rem",
                height: "100%",
              }}
            >
              {Table()}
            </table>
          </div>
        </div>
        <div className="section" style={{ paddingBottom: "2rem" }}>
          <h1></h1>
          <p>
            <Trans i18nKey="ss.c_text" />
          </p>

          <div className="image-default-container">
            <img className="image-default" src="/images/childrens_sunday_school_art.jpeg"></img>
            {/* <p style={{ fontFamily: "WMFD-Bold" }}></p> */}
          </div>
        </div>
      </>
    );
  }

  function AdultsSundaySchool() {
    return (
      <>
        <div id="adults-sunday-school" className="section">
          <h1>{t("ss.a_heading")}</h1>
          <p>
            <Trans i18nKey="ss.a_text" />
          </p>
        </div>
        <div className="image-default-container">{SundaySchoolTable()}</div>
        <div className="section" style={{ paddingBottom: "2rem" }}>
          <p>
            <Trans i18nKey="ss.b_text" />
          </p>
        </div>
      </>
    );
  }

  return (
    <div className="container" style={{ gap: "0px" }}>
      <div className="banner" style={{ backgroundPosition: "50% 55%", backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/images/banners/sunday_school_banner.jpg)" }}>
        <p className="banner-text">{t("ss.banner_text")}</p>
        <p className="banner-subtext">{t("ss.banner_subtext")}</p>
      </div>

      <div className="section" style={{ paddingBottom: "2rem" }}>
        <h3>
          <Trans i18nKey="ss.text" />
        </h3>

        <div className="sunday-school-nav-buttons">
          <button
            onClick={() => {
              setABannerOpen(true);
              setCBannerOpen(false);
            }}
            className="button"
          >
            {t("ss.a_heading")}
          </button>
          <button
            onClick={() => {
              setABannerOpen(false);
              setCBannerOpen(true);
            }}
            className="button"
          >
            {t("ss.c_heading")}
          </button>
        </div>
      </div>

      {isABannerOpen && AdultsSundaySchool()}
      {isCBannerOpen && ChildrensSundaySchool()}
    </div>
  );
}
