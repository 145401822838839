// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mission-card-container {
  display: flex;
  gap: 2rem;
  width: 100%;
}

.mission-card {
  border-radius: 1.5rem;
  padding: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  color: white;
  gap: 1rem;
}

.mission-card-title {
  font-family: "WMFD-SemiBold";
  text-transform: uppercase;
  font-size: 2rem;
}

.mission-text {
  text-align: center;
}

.mission-text-container {
  flex: 1 1;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Mission.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,WAAW;AACb;;AAEA;EACE,qBAAqB;EACrB,aAAa;EACb,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,SAAO;EACP,YAAY;EACZ,SAAS;AACX;;AAEA;EACE,4BAA4B;EAC5B,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,SAAO;AACT","sourcesContent":[".mission-card-container {\r\n  display: flex;\r\n  gap: 2rem;\r\n  width: 100%;\r\n}\r\n\r\n.mission-card {\r\n  border-radius: 1.5rem;\r\n  padding: 1rem;\r\n  text-align: center;\r\n  display: flex;\r\n  flex-direction: column;\r\n  flex: 1;\r\n  color: white;\r\n  gap: 1rem;\r\n}\r\n\r\n.mission-card-title {\r\n  font-family: \"WMFD-SemiBold\";\r\n  text-transform: uppercase;\r\n  font-size: 2rem;\r\n}\r\n\r\n.mission-text {\r\n  text-align: center;\r\n}\r\n\r\n.mission-text-container {\r\n  flex: 1;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
