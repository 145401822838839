import { useTranslation, Trans } from "react-i18next";

export default function Members() {
  const { t, i18n } = useTranslation();

  return (
    <div className="container">
      <div className="banner" style={{ backgroundPosition: "50% 70%", backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(/images/banners/members_banner.jpg)" }}>
        <p className="banner-text">{t("members.banner_text")}</p>
      </div>
      <div className="section">
        <h3>{t("members.a_text")}</h3>
      </div>
    </div>
  );
}
