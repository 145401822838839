import { useTranslation, Trans } from "react-i18next";

export default function CommunityOutreach() {
  const { t, i18n } = useTranslation();
  return (
    <div className="container">
      <div className="banner" style={{ backgroundPosition: "50% 70%", backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/images/banners/community_outreach_banner.jpg)" }}>
        <p className="banner-text">{t("co.banner_text")}</p>
        <p className="banner-subtext">{t("co.banner_subtext")}</p>
      </div>

      <div className="section">
        <h1> {t("co.a_heading")}</h1>
        <p>
          <Trans i18nKey="co.a_text" />
        </p>
        <div className="image-default-container">
          <img className="image-default" src="/images/evangelism_ministry.jpg"></img>
          <p style={{ fontFamily: "WMFD-Bold" }}>{t("co.a_caption")}</p>
        </div>
      </div>

      <div className="section" style={{ paddingBottom: "1rem" }}>
        <h1> {t("co.b_heading")}</h1>
        <p>
          <Trans i18nKey="co.b_text" />
        </p>
        <div className="image-default-container">
          <img className="image-default" src="/images/nursing_home_visit.jpg"></img>
          <p style={{ fontFamily: "WMFD-Bold" }}>{t("co.b_heading")}</p>
        </div>
      </div>

      <div className="section" style={{ paddingBottom: "1rem" }}>
        <h1> {t("co.c_heading")}</h1>
        <p>
          <Trans i18nKey="co.c_text" />
        </p>
      </div>
    </div>
  );
}
