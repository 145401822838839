// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#childrens-sunday-school {
  scroll-margin-top: 45vh;
}

#adults-sunday-school {
  scroll-margin-top: 45vh;
}

.sunday-school-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background-size: cover;
  gap: 4rem;
  height: 40vh;
  width: 100%;
}

.sunday-school-nav-buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  gap: 2rem;
}

.sunday-school-expand-less {
  margin-top: -2rem;
}

@media (max-width: 768px) {
  .sunday-school-banner {
    height: 35vh;
    gap: 3rem;
  }

  .sunday-school-nav-buttons {
    flex-direction: column;
    gap: 1rem;
  }
}

@media (max-width: 425px) {
  .sunday-school-banner {
    height: 25vh;
    gap: 1rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/SundaySchool.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,yBAAyB;EACzB,sBAAsB;EACtB,SAAS;EACT,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,uBAAuB;EACvB,SAAS;AACX;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE;IACE,YAAY;IACZ,SAAS;EACX;;EAEA;IACE,sBAAsB;IACtB,SAAS;EACX;AACF;;AAEA;EACE;IACE,YAAY;IACZ,SAAS;EACX;AACF","sourcesContent":["#childrens-sunday-school {\r\n  scroll-margin-top: 45vh;\r\n}\r\n\r\n#adults-sunday-school {\r\n  scroll-margin-top: 45vh;\r\n}\r\n\r\n.sunday-school-banner {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  justify-content: flex-end;\r\n  background-size: cover;\r\n  gap: 4rem;\r\n  height: 40vh;\r\n  width: 100%;\r\n}\r\n\r\n.sunday-school-nav-buttons {\r\n  display: flex;\r\n  flex-direction: row;\r\n  width: 100%;\r\n  justify-content: center;\r\n  gap: 2rem;\r\n}\r\n\r\n.sunday-school-expand-less {\r\n  margin-top: -2rem;\r\n}\r\n\r\n@media (max-width: 768px) {\r\n  .sunday-school-banner {\r\n    height: 35vh;\r\n    gap: 3rem;\r\n  }\r\n\r\n  .sunday-school-nav-buttons {\r\n    flex-direction: column;\r\n    gap: 1rem;\r\n  }\r\n}\r\n\r\n@media (max-width: 425px) {\r\n  .sunday-school-banner {\r\n    height: 25vh;\r\n    gap: 1rem;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
