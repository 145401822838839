import "./ContactForm.css";
import { useState } from "react";

import { useTranslation, Trans } from "react-i18next";

const FORM_ENDPOINT = "https://public.herotofu.com/v1/c91be6d0-cba7-11ee-bb69-515451de93af";

export default function ContactForm() {
  const [submitted, setSubmitted] = useState(false);
  const { t, i18n } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();

    const inputs = e.target.elements;
    const data = {};

    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].name) {
        data[inputs[i].name] = inputs[i].value;
      }
    }

    fetch(FORM_ENDPOINT, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Form response was not ok");
        }

        setSubmitted(true);
      })
      .catch((err) => {
        // Submit the form manually
        e.target.submit();
      });
  };

  if (submitted) {
    return (
      <>
        <p style={{ color: "white", fontSize: "1.5rem", textAlign: "center" }}>{t("cf.confirm_message")}</p>
      </>
    );
  }

  return (
    <>
      <form id="contact-form" action={FORM_ENDPOINT} method="POST" acceptCharset="UTF-8" onSubmit={handleSubmit}>
        <label for="name">{t("cf.name")}</label>
        <input type="text" id="name" name="name" required></input>
        <label for="email">{t("cf.email")}</label>
        <input type="text" id="email" name="email" required></input>
        <label for="phone">{t("cf.phone")}</label>
        <input type="text" id="phone" name="phone"></input>
        <label for="message">{t("cf.message")}</label>
        <textarea maxLength="2000" rows="5" type="text" id="message" name="message" required></textarea>
        <button id="send-button" className="button" type="submit">
          <span class="material-symbols-outlined">send</span>
          {t("cf.button")}
        </button>
      </form>
    </>
  );
}
