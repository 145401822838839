import React from "react";
import { useTranslation, Trans } from "react-i18next";

const goalsEng = {
  "SPIRITUAL GROWTH": "Discipleship facilitates the spiritual growth of believers, helping them deepen their relationship with God, understand His Word, and mature in their faith.",
  "COMMUNITY BUILDING": "It fosters a sense of community within the church as believers come together to learn, grow, and support one another in their spiritual journeys.",
  "EQUIPPING FOR MINISTRY": "Discipleship equips believers with the knowledge, skills, and spiritual gifts necessary for effective ministry and service within the church and beyond.",
  "THE GREAT COMMISSION": "Through discipleship, believers are empowered to make more disciples, fulfilling the Great Commission to spread the Gospel and make disciples of all nations.",
  "SPIRITUAL ACCOUNTABILITY": "It provides a framework for accountability and mutual encouragement among believers, helping them stay grounded in their faith and accountable to one another.",
};

const goalsTrad = {
  屬靈成長H: "門徒訓練促進信徒的屬靈成長，幫助他們加深與上帝的關係，理解祂的話語，並在信仰上成熟。",
  團契建立: "它在教會內培養一個合一而彼此關顧的團契，使信徒在屬靈旅程中聚集在一起學習、成長並互相支持。",
  事奉裝備: "門徒訓練為信徒在教會內外進行有效事奉和服待所需的知識、技能和屬靈恩賜。",
  大使命: "通過門徒訓練，信徒被造就成能建立更多門徒的門徒，實現了大使命，傳揚福音, 並使萬民作主的門徒。",
  屬靈責任: "它提供了信徒之間, 彼此負責和互相鼓勵的框架，幫助他們保持信仰根基，並互相勉勵問責。",
};

const goalsSimp = {
  属灵成长: "门徒训练促进信徒的属灵成长，帮助他们加深与上帝的关系，理解祂的话语，并在信仰上成熟。",
  团契建立: "它在教会内培养一个合一而彼此关顾的团契，使信徒在属灵旅程中聚集在一起学习、成长并互相支持。",
  事奉装备: "门徒训练为信徒在教会内外进行有效事奉和服待所需的知识、技能和属灵恩赐。",
  大使命: "通过门徒训练，信徒被造就成能建立更多门徒的门徒，实现了大使命，传扬福音, 并使万民作主的门徒。",
  属灵责任: "它提供了信徒之间, 彼此负责和互相鼓励的框架，帮助他们保持信仰根基，并互相勉励问责。",
};

export default function Training() {
  const { t, i18n } = useTranslation();

  let goals = {};

  if (window.localStorage.getItem("i18nextLng").includes("en")) {
    goals = goalsEng;
  } else if (window.localStorage.getItem("i18nextLng").includes("trad")) {
    goals = goalsTrad;
  } else if (window.localStorage.getItem("i18nextLng").includes("simp")) {
    goals = goalsSimp;
  }
  // Switches table layout for desktop vs mobile
  const Table = () => {
    const [isMobile, setMobile] = React.useState(window.innerWidth <= 650);

    const updateMedia = () => {
      setMobile(window.innerWidth <= 650);
    };

    React.useEffect(() => {
      window.addEventListener("resize", updateMedia);
      return () => window.removeEventListener("resize", updateMedia);
    });

    return <div>{isMobile ? GoalsMobile() : Goals()}</div>;
  };

  function Goals() {
    const map = Object.keys(goals).map((goal, i) => {
      return (
        <tr className="table-tr">
          {/* <td className="table-td" style={{ borderRight: "1px solid #b9b9b9" }}> */}
          <td className="table-td">
            <div className="table-td-circle">{i + 1}</div>
          </td>
          <td style={{ padding: "1rem", flex: 12 }}>
            <p style={{ fontFamily: "WMFD-Bold", marginBottom: "0.5rem", color: "var(--primary-color)" }}>{goal}</p>
            <p>{goals[goal]}</p>
          </td>
        </tr>
      );
    });

    return map;
  }

  function GoalsMobile() {
    const map = Object.keys(goals).map((goal, i) => {
      return (
        <>
          {/* <tr className="table-td" style={{ borderBottom: "1px solid #b9b9b9" }}> */}
          <tr className="table-td">
            <div className="table-td-circle">{i + 1}</div>
          </tr>
          <tr className="table-tr" style={{ borderBottom: "0px" }}>
            <p style={{ textAlign: "center", fontFamily: "WMFD-Bold", color: "var(--primary-color)" }}>{goal}</p>
          </tr>
          <tr className="table-tr">
            <p>{goals[goal]}</p>
          </tr>
        </>
      );
    });

    return map;
  }
  return (
    <div className="container">
      <div
        className="banner"
        style={{ backgroundPosition: "50% 20%", backgroundColor: "grey", backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/images/banners/training_banner.jpg)" }}
      >
        {/*backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/images/banners/our_beliefs_banner.jpg)" */}
        <p className="banner-text">{t("training.banner_text")}</p>
      </div>
      <div className="section">
        <h3>{t("training.a_text")}</h3>
        <table className="table">{Table()}</table>

        <div></div>
      </div>
      <div className="section" style={{ paddingBottom: "2rem" }}>
        <h1></h1>
        <p>
          <Trans i18nKey="training.b_text">
            <li></li>
          </Trans>
        </p>
      </div>
      <div className="section" style={{ paddingBottom: "2rem" }}>
        <h1>{t("training.c_heading")}</h1>
        <h3>{t("training.c_heading_1")}</h3>
        <p>{t("training.c_text_1")}</p>
        <h3>{t("training.c_heading_2")}</h3>
        <p>{t("training.c_text_2")}</p>
        <h3>{t("training.c_heading_3")}</h3>
        <p>
          <Trans i18nKey="training.c_text_3">
            <li></li>
          </Trans>
        </p>
      </div>
    </div>
  );
}
