import logo from "../logo.jpg";
import "./Home.css";
import "./Missionaries.css";
import { useTranslation, Trans } from "react-i18next";

export default function Missionaries() {
  const { t, i18n } = useTranslation();

  return (
    <div className="container">
      <div className="banner" style={{ backgroundPosition: "50% 70%", backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/images/banners/missionaries_banner.jpg)" }}>
        <p className="banner-text" style={{}}>
          {t("missionaries.banner_text")}
        </p>
        <p className="banner-subtext">{t("missionaries.banner_subtext")}</p>
      </div>
      {/* <div className="section"><h1>Missionaries We Support</h1></div> */}
      <div className="section">
        <h3>
          <Trans i18nKey="missionaries.a_text">
            <sub></sub>
          </Trans>
        </h3>
      </div>
      <div className="section" id="partnerships">
        <h1 style={{ fontFamily: "WMFD-Bold" }}>{t("mission.d_1_heading")}</h1>
        <div className="mrow">
          <div className="mcircle">
            <p className="mtitle" style={{ color: "var(--primary-color)" }}>
              {t("mission.m_1_title")}
            </p>
            <p>{t("mission.m_1_description")}</p>
            <p style={{ fontFamily: "WMFT-SemiBoldItalic" }}>{t("mission.m_1_loc")}</p>
            <p>{t("mission.m_1_org")}</p>
          </div>
          <div className="mcircle">
            <p className="mtitle" style={{ color: "var(--tertiary-color)" }}>
              {t("mission.m_2_title")}
            </p>
            <p>{t("mission.m_2_description")} </p>
            <p>{t("mission.m_2_org")}</p>
          </div>
          <div className="mcircle">
            <p className="mtitle" style={{ color: "var(--secondary-color)" }}>
              {t("mission.m_3_title")}
            </p>
            <p>{t("mission.m_3_description")} </p>
            <p>{t("mission.m_3_org")}</p>
          </div>
        </div>
        <div className="mrow">
          <div className="mcircle">
            <p className="mtitle" style={{ color: "var(--secondary-color)" }}>
              {t("mission.m_4_title")}
            </p>
            <p>{t("mission.m_4_description")} </p>
            <p>{t("mission.m_4_org")}</p>
          </div>
          <div className="mcircle">
            <p className="mtitle" style={{ color: "var(--primary-color)" }}>
              {t("mission.m_5_title")}
            </p>
            <p>{t("mission.m_5_description")}</p>
            <p style={{ fontFamily: "WMFT-SemiBoldItalic" }}>{t("mission.m_5_loc")}</p>
            <p>{t("mission.m_5_org")}</p>
          </div>
          <div className="mcircle">
            <p className="mtitle" style={{ color: "var(--tertiary-color)" }}>
              {t("mission.m_6_title")}
            </p>
            <p>{t("mission.m_6_description")}</p>
            <p style={{ fontFamily: "WMFT-SemiBoldItalic" }}>{t("mission.m_6_loc")}</p>
            <p>{t("mission.m_6_org")}</p>
          </div>
        </div>
      </div>
      <div className="section" style={{ width: "100%", backgroundColor: "#E5E5E5" }}>
        <h1 style={{ fontFamily: "WMFD-Bold" }}> {t("mission.d_2_heading")}</h1>
        <div className="arow left-offset">
          <a style={{ borderBottom: "none" }} href="https://www.vom.com.au/">
            <img src="/images/voiceofthematyrs.png" className="aimage" alt="voice of the matyrs logo"></img>
          </a>
          <a style={{ borderBottom: "none" }} href="https://wec.com.au/">
            <img src="/images/wec.jpg" className="aimage" alt="wec logo"></img>
          </a>
        </div>
        <div className="arow right-offset">
          <a style={{ borderBottom: "none" }} href="https://www.cimaustralia.org/">
            <img src="/images/cim.png" className="aimage" alt="chinese international mission logo"></img>
          </a>
          <a style={{ borderBottom: "none" }} href="https://www.restaurantmission.org.au/en">
            <img src="/images/restaurant_mission.png" className="aimage" alt="resturant mission logo"></img>
          </a>
        </div>
      </div>
    </div>
  );
}
