import "./Sermon.css";
import React from "react";
import { useTranslation, Trans } from "react-i18next";

export default function Services() {
  const { t, i18n } = useTranslation();
  const [sermon, setSermon] = React.useState("");

  React.useEffect(() => {
    const section = document.querySelector(".info-section");

    if (sermon.length > 0) {
      section.scrollIntoView();
    }
  }, [sermon]);

  function sermonSection() {
    if (sermon === "eng") {
      return (
        <>
          <div className="sermon-container" style={{ backgroundColor: "#f5f5f5" }}>
            <div style={{ height: "3rem", width: "100%", backgroundColor: "var(--primary-color)" }}></div> <h3> {t("sermons.latest")}</h3>
            <div style={{ height: "50vh", width: "89vw", backgroundColor: "grey", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <h3 style={{ fontSize: "5rem", lineHeight: "5rem" }}>{t("events.a_text")}</h3>
            </div>
            {/* <div className="sermon-video-wrapper">
              <iframe className="sermon-iframe" src="https://www.youtube.com/embed/?listType=playlist&list=PLXywOnMYnarmtHpuUuvWji3uqeFBtApAj" frameborder="0" allowFullScreen></iframe>
            </div>
            <a style={{ width: "fit-content", margin: "1rem 0rem" }} className="button" href="https://www.youtube.com/playlist?list=PLXywOnMYnarmtHpuUuvWji3uqeFBtApAj">
              View all sermons
            </a> */}
          </div>
        </>
      );
    } else if (sermon === "mand") {
      return (
        <>
          <div className="sermon-container" style={{ backgroundColor: "#f5f5f5" }}>
            <div style={{ height: "3rem", width: "100%", backgroundColor: "var(--secondary-color)" }}></div> <h3> {t("sermons.latest")}</h3>
            <div className="sermon-video-wrapper">
              <iframe className="sermon-iframe" src="https://www.youtube.com/embed/?listType=playlist&list=PLXywOnMYnarmtHpuUuvWji3uqeFBtApAj" frameborder="0" allowFullScreen></iframe>
            </div>
            <a style={{ width: "fit-content", margin: "1rem 0rem" }} className="button" href="https://www.youtube.com/playlist?list=PLXywOnMYnarmtHpuUuvWji3uqeFBtApAj">
              {t("sermons.view_all")}
            </a>
          </div>
        </>
      );
    } else if (sermon === "cant") {
      return (
        <>
          <div className="sermon-container" style={{ backgroundColor: "#f5f5f5" }}>
            <div style={{ height: "3rem", width: "100%", backgroundColor: "var(--tertiary-color)" }}> </div> <h3> {t("sermons.latest")}</h3>
            <div className="sermon-video-wrapper">
              <iframe className="sermon-iframe" src="https://www.youtube.com/embed/?listType=playlist&list=PLXywOnMYnarnG4yNrB-isB1D_Sw2vrd1K" frameborder="0" allowFullScreen></iframe>
            </div>
            <a style={{ width: "fit-content", margin: "1rem 0rem" }} className="button" href="https://www.youtube.com/playlist?list=PLXywOnMYnarnG4yNrB-isB1D_Sw2vrd1K">
              {t("sermons.view_all")}
            </a>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  }

  return (
    <div className="container">
      <div className="banner" style={{ backgroundPosition: "50% 50%", backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/images/banners/services_banner.jpg)" }}>
        <p className="banner-text">{t("sermons.banner_text")}</p>
        <p className="banner-subtext"></p>
      </div>
      <div className="section" style={{ marginTop: "2rem" }}>
        <div className="card-container">
          <div className="card">
            <div className="circle" style={{ backgroundColor: "var(--primary-color)" }}></div>
            <p className="service-title">{t("sermons.a_heading")}</p>

            <button
              type="button"
              className="button"
              onClick={() => {
                setSermon("eng");
              }}
            >
              {t("sermons.banner_text")}
            </button>
          </div>
          <div className="card">
            <div className="circle" style={{ backgroundColor: "var(--secondary-color)" }}></div>
            <p className="service-title">{t("sermons.b_heading")}</p>
            <button
              type="button"
              className="button"
              onClick={() => {
                setSermon("mand");
              }}
            >
              {t("sermons.banner_text")}
            </button>
          </div>
          <div className="card">
            <div className="circle" style={{ backgroundColor: "var(--tertiary-color)" }}></div>
            <p className="service-title">{t("sermons.c_heading")}</p>

            <button
              type="button"
              className="button"
              onClick={() => {
                setSermon("cant");
              }}
            >
              {t("sermons.banner_text")}
            </button>
          </div>
        </div>
      </div>

      <div className="section info-section" style={{ borderBottom: "0px" }}>
        {sermonSection()}
      </div>
    </div>
  );
}
