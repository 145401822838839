// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.language-chip {
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  font-family: "WMFD-SemiBold";
  color: white;
}

.language-chip-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.event-card-section {
  display: flex;
  flex-direction: row;
  width: 60%;
  padding-bottom: 2rem;
  border-bottom: 1px solid lightgrey;
  gap: 2rem;
  margin-bottom: 2rem;
}

.event-card-row {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1rem;
}

.event-card-date-container {
  display: flex;
  flex-direction: column;
  gap: 0rem;
}

@media (max-width: 1200px) {
  .event-card-section {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .event-card-section {
    flex-direction: column;
    width: 90%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/EventCard.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,oBAAoB;EACpB,4BAA4B;EAC5B,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,UAAU;EACV,oBAAoB;EACpB,kCAAkC;EAClC,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAO;EACP,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,sBAAsB;IACtB,UAAU;EACZ;AACF","sourcesContent":[".language-chip {\r\n  border-radius: 2rem;\r\n  padding: 0.5rem 1rem;\r\n  font-family: \"WMFD-SemiBold\";\r\n  color: white;\r\n}\r\n\r\n.language-chip-container {\r\n  display: flex;\r\n  flex-direction: row;\r\n  gap: 1rem;\r\n}\r\n\r\n.event-card-section {\r\n  display: flex;\r\n  flex-direction: row;\r\n  width: 60%;\r\n  padding-bottom: 2rem;\r\n  border-bottom: 1px solid lightgrey;\r\n  gap: 2rem;\r\n  margin-bottom: 2rem;\r\n}\r\n\r\n.event-card-row {\r\n  display: flex;\r\n  flex-direction: column;\r\n  flex: 1;\r\n  justify-content: center;\r\n  align-items: center;\r\n  text-align: center;\r\n  gap: 1rem;\r\n}\r\n\r\n.event-card-date-container {\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 0rem;\r\n}\r\n\r\n@media (max-width: 1200px) {\r\n  .event-card-section {\r\n    width: 80%;\r\n  }\r\n}\r\n\r\n@media (max-width: 768px) {\r\n  .event-card-section {\r\n    flex-direction: column;\r\n    width: 90%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
