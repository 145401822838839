// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0rem;
  background-color: var(--secondary-color);
  width: 100%;
}

#contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  gap: 1rem;
}

#contact-form label {
  font-family: "WMFD-SemiBold";
  color: white;
  width: 100%;
}
#contact-form input,
textarea {
  padding: 0.5rem;
  letter-spacing: normal;
  font-family: "WMFT-SemiBold";
  border-radius: 0.5rem;
  border: none;
  width: 100%;
}

#contact-form input:focus,
textarea:focus {
  border: 0.5px solid var(--primary-color);
}

#send-button {
  display: flex;
  margin-top: 1rem;
  width: -moz-fit-content;
  width: fit-content;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

@media (max-width: 1200px) {
  #contact-form {
    width: 80%;
  }
}

@media (max-width: 768px) {
  #contact-form {
    width: 90%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ContactForm.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;EAClB,wCAAwC;EACxC,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,UAAU;EACV,SAAS;AACX;;AAEA;EACE,4BAA4B;EAC5B,YAAY;EACZ,WAAW;AACb;AACA;;EAEE,eAAe;EACf,sBAAsB;EACtB,4BAA4B;EAC5B,qBAAqB;EACrB,YAAY;EACZ,WAAW;AACb;;AAEA;;EAEE,wCAAwC;AAC1C;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,uBAAkB;EAAlB,kBAAkB;EAClB,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":[".form-container {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  padding: 2rem 0rem;\r\n  background-color: var(--secondary-color);\r\n  width: 100%;\r\n}\r\n\r\n#contact-form {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  width: 60%;\r\n  gap: 1rem;\r\n}\r\n\r\n#contact-form label {\r\n  font-family: \"WMFD-SemiBold\";\r\n  color: white;\r\n  width: 100%;\r\n}\r\n#contact-form input,\r\ntextarea {\r\n  padding: 0.5rem;\r\n  letter-spacing: normal;\r\n  font-family: \"WMFT-SemiBold\";\r\n  border-radius: 0.5rem;\r\n  border: none;\r\n  width: 100%;\r\n}\r\n\r\n#contact-form input:focus,\r\ntextarea:focus {\r\n  border: 0.5px solid var(--primary-color);\r\n}\r\n\r\n#send-button {\r\n  display: flex;\r\n  margin-top: 1rem;\r\n  width: fit-content;\r\n  justify-content: center;\r\n  align-items: center;\r\n  gap: 0.5rem;\r\n}\r\n\r\n@media (max-width: 1200px) {\r\n  #contact-form {\r\n    width: 80%;\r\n  }\r\n}\r\n\r\n@media (max-width: 768px) {\r\n  #contact-form {\r\n    width: 90%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
