import "./Logo.css";
import { Link } from "react-router-dom";

export default function Logo() {
  return (
    <Link className="logo-container" to={"/"}>
      <img className="logo" src="/images/bhcbc-logo.png"></img>
    </Link>
  );
}
