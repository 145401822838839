import "./PastoralTeam.css";

import { useTranslation, Trans } from "react-i18next";

export default function PastoralTeam() {
  const { t, i18n } = useTranslation();

  return (
    <div className="container">
      <div className="banner" style={{ backgroundPosition: "50% 5%", backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(/images/banners/pastoral_team_banner.jpg)" }}>
        <p className="banner-text">{t("pt.banner_text")}</p>
        <p className="banner-subtext">{t("pt.banner_subtext")}</p>
      </div>
      <div className="psection" style={{ marginTop: "2rem" }}>
        <div className="pinfo">
          <p className="ptitle">{t("pt.a_title")}</p>
          <p className="pname">{t("pt.a_name")}</p>
          <p className="pministry">{t("pt.a_ministry")}</p>
          <img className="pimage" src="/images/reverend_sunny_tse.jpg" alt="picture or reverend sunny tse"></img>
          <p style={{ display: "flex", alignItems: "center", fontFamily: "WMFD-SemiBold" }}>
            <span class="material-symbols-outlined" style={{}}>
              mail
            </span>
            : rst@bhcbc.org.au
          </p>
        </div>

        <p className="ptext">
          <Trans i18nKey="pt.a_text" />
        </p>
      </div>
      <div className="psection" style={{ paddingBottom: "1rem" }}>
        <div className="pinfo">
          <p className="ptitle">{t("pt.b_title")}</p>
          <p className="pname">{t("pt.b_name")}</p>
          <p className="pministry">{t("pt.b_ministry")}</p>
          <img className="pimage" src="/images/pastor_kk_leung.jpg" alt="picture of pastor kk leung"></img>
          <p style={{ display: "flex", alignItems: "center", fontFamily: "WMFD-SemiBold" }}>
            <span class="material-symbols-outlined" style={{}}>
              mail
            </span>
            : rkkleung@bhcbc.org.au
          </p>
        </div>
        <p className="ptext">
          <Trans i18nKey="pt.b_text" />
        </p>
      </div>
      <div className="psection" style={{ paddingBottom: "1rem" }}>
        <div className="pinfo">
          <p className="ptitle">{t("pt.c_title")}</p>
          <p className="pname">{t("pt.c_name")}</p>
          <p className="pministry">{t("pt.c_ministry")}</p>
          <img className="pimage" src="/images/pastor_jason_zhao.jpg" alt="picture of pastor jason zhao"></img>
          <p style={{ display: "flex", alignItems: "center", fontFamily: "WMFD-SemiBold" }}>
            <span class="material-symbols-outlined" style={{}}>
              mail
            </span>
            : jzhao@bhcbc.org.au
          </p>
        </div>
        <p className="ptext">
          <Trans i18nKey="pt.c_text" />
        </p>
      </div>
      <div className="psection" style={{ paddingBottom: "1rem", marginBottom: "1rem" }}>
        <div className="pinfo">
          <p className="ptitle">{t("pt.d_title")}</p>
          <p className="pname">{t("pt.d_name")}</p>
          <p className="pministry">{t("pt.d_ministry")}</p>
          <img className="pimage" src="/images/pastor_robert_robayna.jpg" alt="picture of pastor robert robayna"></img>
          <p style={{ display: "flex", alignItems: "center", fontFamily: "WMFD-SemiBold" }}>
            <span class="material-symbols-outlined" style={{}}>
              mail
            </span>
            : rrobayna@bhcbc.org.au
          </p>
        </div>
        <p className="ptext">
          <Trans i18nKey="pt.d_text" />
        </p>
      </div>
      <div className="psection" style={{ paddingBottom: "1rem", marginBottom: "1rem" }}>
        <div className="pinfo">
          <p className="pname">{t("pt.e_name")}</p>
          <p className="pministry">{t("pt.e_ministry")}</p>
          <div className="pimage pnoimage">G F</div>
          <p style={{ display: "flex", alignItems: "center", fontFamily: "WMFD-SemiBold" }}>
            <span class="material-symbols-outlined" style={{}}>
              phone
            </span>
            : 02 9554 8268
          </p>
          <p style={{ display: "flex", alignItems: "center", fontFamily: "WMFD-SemiBold" }}>
            <span class="material-symbols-outlined" style={{}}>
              mail
            </span>
            : grace@bhcbc.org.au
          </p>
        </div>
        <p className="ptext">
          <Trans i18nKey="pt.e_office_hours">
            <span style={{ fontFamily: "WMFD-Bold" }}></span>Wednesday, Thursday and Friday, 9:30am to 1:30pm.
          </Trans>
          <Trans i18nKey="pt.e_text" />
        </p>
      </div>
    </div>
  );
}
