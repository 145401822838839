// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-special {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.3) 100%);
}

.sublinks-special {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
}

.link-special:hover {
}

.link-selected-special span {
  color: var(--primary-color);
}

.link-special:hover span {
  color: var(--primary-color);
}

@media (max-width: 1000px) {
  .header-special {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
  }
}

@media (max-width: 425px) {
}
`, "",{"version":3,"sources":["webpack://./src/components/SpecialNavbar.css"],"names":[],"mappings":"AAAA;EACE,mFAAmF;AACrF;;AAEA;EACE,iFAAiF;AACnF;;AAEA;AACA;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE;IACE,iFAAiF;EACnF;AACF;;AAEA;AACA","sourcesContent":[".header-special {\r\n  background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.3) 100%);\r\n}\r\n\r\n.sublinks-special {\r\n  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);\r\n}\r\n\r\n.link-special:hover {\r\n}\r\n\r\n.link-selected-special span {\r\n  color: var(--primary-color);\r\n}\r\n\r\n.link-special:hover span {\r\n  color: var(--primary-color);\r\n}\r\n\r\n@media (max-width: 1000px) {\r\n  .header-special {\r\n    background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);\r\n  }\r\n}\r\n\r\n@media (max-width: 425px) {\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
