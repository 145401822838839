import logo from "../logo.jpg";
// import "./ChurchHistory.css";
import "./Tables.css";
import React from "react";
import { useTranslation, Trans } from "react-i18next";

const churchHistoryEng = {
  1986: "A leader from Beverly Hills Baptist Church, saw an increase in Chinese immigrants in the community. They had a vision to share the Gospel with these people.",
  1987: "With the assistance of Central Baptist Church, they sent out an evangelism team that could speak Cantonese to visit the Chinese residents in the area, share the Gospel with them, invite them to church gatherings, and provide Cantonese translation during English worship services. The church expanded its Chinese ministry, offering Bible study classes and establishing both English and Chinese classes to help Chinese individuals become involved in the church. ",
  1988: "The number of Chinese individuals attending Sunday worship had grown in number.",
  1989: "The church started conducting Cantonese Worship Services. [Note: Beverly Hills Baptist Church had changed its name to Kingsgrove Beverly Hills Baptist Church]",
  1994: 'The Chinese Worship Service at the church grew to 130 people. The church established a planted church in Blakehurst, naming it "Blakehurst Chinese Baptist Church," which began with approximately 30 people. In July, the planted church relocated to Hurstville and was renamed "Hurstville Chinese Baptist Church." In October, it separated from the mother church.',
  1995: 'Due to different directions and paces of development between the English and Chinese congregations, the Chinese congregation became an independent entity known as "Beverly Hills Chinese Baptist Church" in November.',
  1997: "The existing church facilities were insufficient to accommodate the growth of both churches, so the church needed to either plant a new church or relocate.",
  1998: "The church embarked on an expansion project and develop youth English Ministry.",
  2000: "The church initiated Personal Evangelism Training, offering the Evangelism Explosion Training, along with Evangelism Follow-Up Mentorship Training. The church rented nearby commercial spaces for youth English Bible Studies and Worship, also decided to hire an English Pastor to develop the English Ministry.",
  2002: "The church purchased the property it currently uses and relocated to the new location on October 28, beginning its first worship service on November 3.",
  2003: 'The church started a program called "Koala Playgroup" for children aged 5 and below to expand outreach ministry.',
  2004: "The church introduced Discipleship Training to equip leaders.",
  2007: "The church established a missions fund to support missionaries, mission organisations, and short-term mission activities.",
  2008: "The Mandarin Bible Study Group began.",
  2009: "The church sent its first missions team to China in January, which served in Heyuan, Xinfeng, and Shaoguan. The Mandarin Ministry was established, and the first Mandarin fellowship took place in October.",
  2010: "The church organised its second mission trip, traveling to Ghana in West Africa. The long-standing issue of inadequate space was resolved as the church hired a primary school for ministry expansion. In November, the English Worship Service and Sunday School began at Beverly Hills Public School.",
  2011: "The church conducted its third mission trip, going to Taichung, Taiwan. In May, the first Mandarin Worship Service began.",
  2018: "The church purchased neighbouring properties to expand God's work.",
  present:
    "The church currently has four pastors: Rev. Sunny Tse for the Cantonese congregation, Pastor Richard Leung for the Christian Education Department, Pastor Jason Zhao for the Mandarin congregation, and Pastor Robert Robayna for the English congregation.",
};

const churchHistoryTrad = {
  1986: "比華利山浸信會（Beverly Hills Baptist Church） 的領袖看見華人移民在區內增加。他們得著異象，要將福音傳給這些人。",
  1987: "在中央浸信會(Central Baptist Church)的幫助下，派出能說廣東話的佈道隊，往區內華人的家拍門，向華人傳福音，邀請他們參加教會聚會，並在英文崇拜中加插廣東話翻譯。教會更推展中文事工，開設查經班，設立英文班和中文班以幫助華人投入教會。",
  1988: "參與主日崇拜的華人增加至一定的數目。",
  1989: "教會開展廣東話崇拜。(註：「比華利山浸信會」Beverly Hills Baptist Church 後來改名為「景士高比華利山浸信會」 [Kingsgrove Beverly Hills Baptist Church])",
  1994: "中文崇拜人數增至130人，教會在Blakehurst植堂，分堂定名為伯利林華人浸信會（Blakehurst Chinese Baptist Church)，開始時約30人。7月，分堂遷到Hurstville, 改名為樂事圍華人浸信會（Hurstville Chinese Baptist Church)，10月脫離母會成為自立教會。",
  1995: "由於英文堂和中文堂的發展方向和步伐不同，中文堂於11月自立成為比華利山華人浸信會（Beverly Hills Chinese Baptist Church)。",
  1997: "現有堂址不足供應兩間教會發展，教會需要植堂或遷堂。",
  1998: "教會決定開展擴堂計劃和發展青少年英語事工。",
  2000: "教會推動個人佈道訓練，開辦三福佈道訓練，同時開辦八課栽培員訓練。教會租借附近商舖作青少年英語查經和崇拜之用，並決定聘請英語牧者發展英語事工。",
  2002: "教會購買我們今天使用的地方，並於10月28日遷往新堂址，在11月3日開始第一堂崇拜。",
  2003: "教會開辦小樹熊遊戲組，供5歲及以下的兒童參加，以拓展外展事工。",
  2004: "教會開辦門徒訓練課程，培訓領袖。",
  2007: "教會成立宣教基金，以支持宣教士、宣教機構和教會的短宣活動。",
  2008: "開始國語查經班。",
  2009: "以教會名義的第一隊短宣隊在1月前往中國的河源、新豐和韶關宣教。國語事工組成立，第一次國語團契在10月舉行。",
  2010: "教會第二次短宣，前往西非迦納。長期以來地方不足的問題得到解決，神為我們預備一間小學拓展教會事工。在11月，英文崇拜和主日學第一次在比華利山公立學校舉行。",
  2011: "教會第三次短宣，前往台中。5月，開始第一堂國語崇拜。",
  2018: "教會購入鄰舍物業以拓展神的事工。",
  現在: "現在, 教會有四位牧者：謝健新牧師 - 粵語堂牧者，梁經球傳道 - 基督教教育部牧者，趙江萃傳道 - 國語堂牧者，Robert Robayna傳道 - 英語堂牧者\n",
};

const churchHistorySimp = {
  1986: "比华利山浸信会（Beverly Hills Baptist Church） 的领袖看见华人移民在区内增加。他们得着异象，要将福音传给这些人。",
  1987: "在中央浸信会(Central Baptist Church)的帮助下，派出能说广东话的布道队，往区内华人的家拍门，向华人传福音，邀请他们参加教会聚会，并在英文崇拜中加插广东话翻译。教会更推展中文事工，开设查经班，设立英文班和中文班以帮助华人投入教会。",
  1988: "参与主日崇拜的华人增加至一定的数目。",
  1989: "教会开展广东话崇拜。 (注：「比华利山浸信会」Beverly Hills Baptist Church 后来改名为「景士高比华利山浸信会」 [Kingsgrove Beverly Hills Baptist Church])",
  1994: "中文崇拜人数增至130人，教会在Blakehurst植堂，分堂定名为伯利林华人浸信会（Blakehurst Chinese Baptist Church)，开始时约30人。 7月，分堂迁到Hurstville, 改名为乐事围华人浸信会（Hurstville Chinese Baptist Church)，10月脱离母会成为自立教会。",
  1995: "由于英文堂和中文堂的发展方向和步伐不同，中文堂于11月自立成为比华利山华人浸信会（Beverly Hills Chinese Baptist Church)。",
  1997: "现有堂址不足供应两间教会发展，教会需要植堂或迁堂。",
  1998: "教会决定开展扩堂计划和发展青少年英语事工。",
  2000: "教会推动个人布道训练，开办三福布道训练，同时开办八课栽培员训练。教会租借附近商铺作青少年英语查经和崇拜之用，并决定聘请英语牧者发展英语事工。",
  2002: "教会购买我们今天使用的地方，并于10月28日迁往新堂址，在11月3日开始第一堂崇拜。",
  2003: "教会开办小树熊游戏组，供5岁及以下的儿童参加，以拓展外展事工。",
  2004: "教会开办门徒训练课程，培训领袖。",
  2007: "教会成立宣教基金，以支持宣教士、宣教机构和教会的短宣活动。",
  2008: "开始国语查经班。",
  2009: "以教会名义的第一队短宣队在1月前往中国的河源、新丰和韶关宣教。国语事工组成立，第一次国语团契在10月举行。",
  2010: "教会第二次短宣，前往西非迦纳。长期以来地方不足的问题得到解决，神为我们预备一间小学拓展教会事工。在11月，英文崇拜和主日学第一次在比华利山公立学校举行。",
  2011: "教会第三次短宣，前往台中。 5月，开始第一堂国语崇拜。",
  2018: "教会购入邻舍物业以拓展神的事工。",
  现在: "现在, 教会有四位牧者：谢健新牧师 - 粤语堂牧者，梁经球传道 - 基督教教育部牧者，赵江萃传道 - 国语堂牧者，Robert Robayna传道 - 英语堂牧者\n",
};

export default function ChurchHistory() {
  const { t, i18n } = useTranslation();

  let churchHistory = {};

  if (window.localStorage.getItem("i18nextLng").includes("en")) {
    churchHistory = churchHistoryEng;
  } else if (window.localStorage.getItem("i18nextLng").includes("trad")) {
    churchHistory = churchHistoryTrad;
  } else if (window.localStorage.getItem("i18nextLng").includes("simp")) {
    churchHistory = churchHistorySimp;
  }

  // Switches table layout for desktop vs mobile
  const Table = () => {
    const [isMobile, setMobile] = React.useState(window.innerWidth <= 650);

    const updateMedia = () => {
      setMobile(window.innerWidth <= 650);
    };

    React.useEffect(() => {
      window.addEventListener("resize", updateMedia);
      return () => window.removeEventListener("resize", updateMedia);
    });

    return <div>{isMobile ? ChurchHistoryTableMobile() : ChurchHistoryTable()}</div>;
  };

  function ChurchHistoryTable() {
    const map = Object.keys(churchHistory).map((year, i) => {
      return (
        <tr className="table-tr">
          <td className="table-td">
            {/* <td className="table-td" style={{ borderRight: "1px solid #b9b9b9" }}> */}
            <div className="table-td-circle">{year}</div>
          </td>
          <td style={{ display: "flex", alignItems: "center", flex: 12 }}>
            <p style={{ margin: "1rem" }}>{churchHistory[year]} </p>
          </td>
        </tr>
      );
    });

    return map;
  }

  function ChurchHistoryTableMobile() {
    const map = Object.keys(churchHistory).map((year, i) => {
      return (
        <>
          {/* <tr className="table-td" style={{ borderBottom: "1px solid #b9b9b9" }}> */}
          <tr className="table-td">
            <div className="table-td-circle"> {year}</div>
          </tr>
          <tr className="table-tr" style={{ padding: "1rem" }}>
            <p>{churchHistory[year]}</p>
          </tr>
        </>
      );
    });

    return map;
  }

  return (
    <div className="container">
      <div className="banner" style={{ backgroundPosition: "50% 80%", backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/images/banners/church_history_banner.jpg)" }}>
        <p className="banner-subtext">{t("history.banner_subtext")}</p>
        <p className="banner-text">{t("history.banner_text")}</p>
      </div>
      <div className="section" style={{ paddingBottom: "1rem" }}>
        {/* <h1>Our History</h1> */}
        <h3>{t("history.a_text")}</h3>
        <table className="table church-history-table">{Table()}</table>
      </div>
    </div>
  );
}
