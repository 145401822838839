import logo from "../logo.jpg";
import "./Home.css";
import { useTranslation, Trans } from "react-i18next";

export default function Location() {
  const { t, i18n } = useTranslation();

  return (
    <div className="container">
      <div className="banner" style={{ backgroundPosition: "50% 45%", backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/images/banners/location_banner.jpg)" }}>
        <p className="banner-text">{t("location.banner_text")}</p>
      </div>
      <div className="section" style={{ paddingTop: "3rem" }}>
        <h3 style={{ textAlign: "center" }}>{t("location.a_text")}</h3>
      </div>
      <div className="section">
        <h1>{t("location.b_heading")}</h1>
        <p style={{ textAlign: "center" }}>{t("location.b_text")}</p>
      </div>
      <div id="map">
        <iframe
          className="map-iframe"
          height="500px"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
          style={{ borderRadius: "1rem" }}
          src="https://www.google.com/maps/embed/v1/place?q=Beverly+Hills+Chinese+Baptist+Church,+Stoney+Creek+Road,+Beverly+Hills+NSW,+Australia&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
        ></iframe>
        <p style={{ maxWidth: "80%", fontFamily: "WMFD-Bold", margin: "0 1rem", textAlign: "center" }}>192 Stoney Creek Road, Beverly Hills, NSW 2209</p>
      </div>
      <div className="section">
        <h1>{t("location.c_heading")}</h1>
        <p style={{ textAlign: "center" }}>{t("location.c_text")}</p>
      </div>
      <div id="map">
        <iframe
          className="map-iframe"
          height="500px"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
          style={{ borderRadius: "1rem" }}
          src="https://www.google.com/maps/embed/v1/place?q=Beverly+Hills+Public+School,+King+Georges+Road,+Beverly+Hills+NSW,+Australia&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
        ></iframe>
        <p style={{ maxWidth: "80%", fontFamily: "WMFD-Bold", margin: "0 1rem", textAlign: "center" }}>Beverly Hills Public School, Stoney Creek and King Georges Roads, Beverly Hills, NSW 2209</p>
      </div>
    </div>
  );
}
