import "./Home.css";
import "./Banners.css";

import { useTranslation, Trans } from "react-i18next";

import React from "react";
const infoEng = {
  ages: "Children: 0-5 years",
  languages: "English, Cantonese, Mandarin",
  where: "Beverly Hills Chinese Baptist Church, 192 Stoney Creek Road, Beverly Hills NSW 2209",
  when: "10am – 12pm, every Tuesday (Closed on school holiday & the first week of each school term)",
  snacks: "$3 per family each time",
  // "surrounding suburbs":
  //   "Kingsgrove, Roselands, Clemton Park, Riverwood, Padstow, Revesby, Lugano, Peakhurst, Mortdale, Oatley, Penshurst, Hurstville, Bexley, Kogarah and more if you are interested!",
};

const infoTrad = {
  兒童: "0-5歲",
  語言: "英語、粤語、普通話",
  地點: "比華利山華人浸信會, 192 Stoney Creek Road, Beverly Hills NSW 2209",
  時間: "星期二上午 10 時至下午 12 時（學校假期及每學期首周暫停）",
  茶點費用: "每個家庭每次$3",
};

const infoSimp = {
  儿童: "0-5岁",
  语言: "英语、粤语、普通话",
  地点: "比华利山华人浸信会, 192 Stoney Creek Road, Beverly Hills NSW 2209",
  时间: "星期二上午 10 时至下午 12 时（学校假期及每学期首周暂停）",
  茶点费用: "每个家庭每次$3",
};

const dates = {
  "term 1": "11/02/25 - 08/04/25",
  "term 2": "06/05/25 - 01/07/25",
  "term 3": "29/07/25 - 23/09/25",
  "term 4": "21/10/25 - 02/12/25",
};

export default function KoalaPlaygroup() {
  const { t, i18n } = useTranslation();

  let info = {};

  if (window.localStorage.getItem("i18nextLng").includes("en")) {
    info = infoEng;
  } else if (window.localStorage.getItem("i18nextLng").includes("trad")) {
    info = infoTrad;
  } else if (window.localStorage.getItem("i18nextLng").includes("simp")) {
    info = infoSimp;
  }

  const [isMobile, setMobile] = React.useState(window.innerWidth <= 650);

  const updateMedia = () => {
    setMobile(window.innerWidth <= 650);
  };

  React.useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const Table1 = () => {
    return <div>{isMobile ? InfoTableMobile() : InfoTable()}</div>;
  };
  const Table2 = () => {
    return <div>{isMobile ? DatesTableMobile() : DatesTable()}</div>;
  };

  function DatesTable2() {
    const map = Object.keys(dates).map((term, i) => {
      return (
        <tr className="invert-tr" style={{ width: "25%" }}>
          <td className=" invert-td" style={{ borderRight: "1px solid #b9b9b9", borderBottom: "1px solid #b9b9b9" }}>
            <div className="table-td-circle">{term}</div>
          </td>
          <td className="invert-td" style={{ borderRight: "1px solid #b9b9b9", borderBottom: "1px solid #b9b9b9" }}>
            {dates[term]}
          </td>
        </tr>
      );
    });

    return map;
  }

  function DatesTable() {
    return (
      <>
        <tr className="table-tr">
          <td className="table-td" style={{ textAlign: "center", borderRight: "1px solid #b9b9b9" }}>
            <div className="table-td-circle">
              term <br />1
            </div>
          </td>
          <td className="table-td" style={{ textAlign: "center", borderRight: "1px solid #b9b9b9" }}>
            <div className="table-td-circle">
              term <br />2
            </div>
          </td>
          <td className="table-td" style={{ textAlign: "center", borderRight: "1px solid #b9b9b9" }}>
            <div className="table-td-circle">
              term <br />3
            </div>
          </td>
          <td className="table-td" style={{ textAlign: "center" }}>
            <div className="table-td-circle">
              term <br />4
            </div>
          </td>
        </tr>
        <tr className="table-tr">
          <td className="table-td" style={{ textAlign: "center", borderRight: "1px solid #b9b9b9" }}>
            {dates["term 1"]}
          </td>

          <td className="table-td" style={{ textAlign: "center", borderRight: "1px solid #b9b9b9" }}>
            {dates["term 2"]}
          </td>

          <td className="table-td" style={{ textAlign: "center", borderRight: "1px solid #b9b9b9" }}>
            {dates["term 3"]}
          </td>

          <td className="table-td" style={{ textAlign: "center" }}>
            {dates["term 4"]}
          </td>
        </tr>
      </>
    );
  }

  function DatesTableMobile() {
    const map = Object.keys(dates).map((term, i) => {
      return (
        <>
          <tr className="table-td">
            <div className="table-td-circle">{term}</div>
          </tr>
          <tr className="table-td" style={{ borderBottom: "1px solid lightgrey" }}>
            {dates[term]}
          </tr>
        </>
      );
    });

    return map;
  }
  function InfoTable() {
    const map = Object.keys(info).map((title, i) => {
      return (
        <tr className="table-tr">
          <td className="table-td-bold" style={{ flex: "1", borderRight: "1px solid #b9b9b9" }}>
            {title}
          </td>
          <td className="table-td" style={{ flex: 3 }}>
            {info[title]}
          </td>
        </tr>
      );
    });

    return map;
  }

  function InfoTableMobile() {
    const map = Object.keys(info).map((title, i) => {
      return (
        <>
          <tr className="table-td-bold" style={{ borderBottom: "0px" }}>
            {title}
          </tr>
          <tr className="table-td" style={{ borderBottom: "1px solid lightgrey" }}>
            {info[title]}
          </tr>
        </>
      );
    });

    return map;
  }

  return (
    <div className="container">
      <div className="banner" style={{ backgroundPosition: "50% 70%", backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/images/banners/koala_banner.jpg)" }}>
        <p className="banner-text">{t("koala.banner_text")}</p>
        <p className="banner-subtext">{t("koala.banner_subtext")}</p>
      </div>
      <div className="section">
        <h3>{t("koala.text")}</h3>
      </div>
      <div className="section" style={{ display: "flex", marginTop: "2rem", flexDirection: "column", alignItems: "center", gap: "2rem" }}>
        <table className="table">{Table2()}</table>
        <table className="table">{Table1()}</table>
      </div>
      <div className="section">
        <h1>{t("koala.a_heading")}</h1>
        <div></div>
        <p>
          <Trans i18nKey="koala.a_text">
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSfXOpuP4u_GvQddRvsRkJgBGUx9H1EXcFRQNr23ztES91WPjw/viewform?usp=sf_link" />
            <a href="https://creatingsafespaces.org.au/resources/"></a>
            <a href="https://ocg.nsw.gov.au/working-children-check"></a>
          </Trans>
          {/* Please feel free to contact us for more information:
          <li>Contact us via SMS/WhatsApp: 0491 743 481 or landline: (02) 9554 8268, or</li>
          <li>
            Fill in the <a href="https://docs.google.com/forms/d/e/1FAIpQLSfXOpuP4u_GvQddRvsRkJgBGUx9H1EXcFRQNr23ztES91WPjw/viewform?usp=sf_link">Koala Playgroup Enquiry Form online, or</a>
          </li>
          <li>Just bring your kids to visit us at Koala Playgroup!</li>
          <br />
          We look forward to seeing you and your little ones!  
          <br />
          <br />
          Koala Playgroup complies with <a href="https://creatingsafespaces.org.au/resources/">Safe Church requirements of Baptist Union NSW & ACT.</a> All volunteers hold valid 
          <a href="https://ocg.nsw.gov.au/working-children-check">Working With Children Check (WWCC) permits.</a> Koala Playgroup adopts the health and hygiene requirements of the church. */}
        </p>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSfXOpuP4u_GvQddRvsRkJgBGUx9H1EXcFRQNr23ztES91WPjw/viewform"
          style={{ marginTop: "1rem", alignSelf: "center" }}
          type="button"
          onClick={() => {}}
          className="button"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("koala.a_button")}
        </a>
      </div>
      <div className="section">
         <h1>{t("koala.b_heading")}</h1>
        <p>
          <Trans i18nKey="koala.b_text">
            <li />
            <li />
          </Trans>
        </p>
      </div>
      <div className="section">
        <h1>{t("koala.c_heading")}</h1>
        <p>
          <Trans i18nKey="koala.c_text">
            <li />
          </Trans>
        </p>

        <div className="image-3rd-container">
          <img className="image-3rd" src="/images/koala_playgroup_1.jpg"></img>
          <img className="image-3rd" src="/images/koala_playgroup_2.jpg"></img>
          <img className="image-3rd" src="/images/koala_playgroup_3.jpg"></img>
          {/* <p style={{ fontFamily: "WMFD-Bold" }}></p> */}
        </div>
      </div>
    </div>
  );
}
