import logo from "../logo.jpg";
import "./Home.css";
import "./Services.css";
import React from "react";
import ServicesInfo from "../components/ServicesInfo";
import { useTranslation, Trans } from "react-i18next";

export default function Services() {
  const { t, i18n } = useTranslation();

  return (
    <div className="container">
      <div className="banner" style={{ backgroundPosition: "50% 50%", backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/images/banners/services_banner.jpg)" }}>
        <p className="banner-text">{t("services.banner_text")}</p>
        <p className="banner-subtext">{t("services.banner_subtext")}</p>
      </div>
      <ServicesInfo />
    </div>
  );
}
