import "./Footer.css";
import textLogo from "../text-logo.png";
import Logo from "./Logo";
import "./Logo.css";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "1rem" }}>
        <Link
          onClick={() => {
            window.scrollTo(0, 0);
          }}
          id="back-to-top"
          style={{ fontFamily: "WMFD-Regular", borderBottom: "1px solid " }}
        >
          {t("footer.btt")}
        </Link>
      </div>
      <footer>
        <div id="footer-container">
          <div id="footer-left">
            <Logo />
            <div>
              <Link style={{ color: "white" }} to={"https://maps.app.goo.gl/T2XwKdNz8UG5BHnu7"}>
                <p id="footer-address">
                  192 Stoney Creek Road,
                  <br />
                  Beverly Hills, NSW 2209
                </p>
              </Link>
            </div>
          </div>

          <div id="footer-right">
            <div id="footer-social-media"></div>
            <div>
              <Link style={{ color: "white" }} to={"/find-us/contacts"}>
                <p id="footer-contact">
                  info@bhcbc.org.au <br />
                  (02) 9554 8268
                </p>
              </Link>
            </div>
          </div>
        </div>
        <p style={{ fontFamily: "WMFD-Regular" }}>© BHCBC 2024</p>
      </footer>
    </>
  );
}
