// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel-container {
  display: flex;
  width: 100%;
}

.carousel-image-container {
  width: 100%;
  height: 100%;
}
.carousel-image-full {
  width: 100%;
  object-fit: contain;
  height: auto;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Carousel.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;AACb;;AAEA;EACE,WAAW;EACX,YAAY;AACd;AACA;EACE,WAAW;EACX,mBAAmB;EACnB,YAAY;AACd","sourcesContent":[".carousel-container {\r\n  display: flex;\r\n  width: 100%;\r\n}\r\n\r\n.carousel-image-container {\r\n  width: 100%;\r\n  height: 100%;\r\n}\r\n.carousel-image-full {\r\n  width: 100%;\r\n  object-fit: contain;\r\n  height: auto;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
