import { Link } from "react-router-dom";

function NoMatch() {
  return (
    <div className="container">
      <h1> Oops... This page does not exist</h1>
      <Link className="button" to={"/"}>
        Home
      </Link>
    </div>
  );
}

export default NoMatch;
