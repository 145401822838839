import React from "react";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

export default function ServicesInfo() {
  const { t, i18n } = useTranslation();
  const [info, setInfo] = React.useState("");

  React.useEffect(() => {
    const section = document.querySelector(".info-section");

    if (info.length > 0) {
      section.scrollIntoView();
    }
  }, [info]);

  function infoSection() {
    if (info === "eng") {
      return (
        <>
          <div className="info">
            <div style={{ height: "3rem", width: "100%", backgroundColor: "var(--primary-color)" }}></div>

            <div
              className="services-banner"
              style={{ backgroundPosition: "50% 65%", backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/images/banners/em_service_banner.jpg)" }}
            >
              <p className="services-banner-text">{t("services.a_banner_text")}</p>
              <p className="services-banner-subtext" style={{ paddingBottom: "2rem" }}>
                {t("services.a_banner_subtext")}
              </p>
            </div>
            <div className="service-info-text">
              <Trans i18nKey="services.a_info" />
            </div>
            <p style={{ textAlign: "center" }}>
              <Trans i18nKey="services.a_text" />
            </p>
            <iframe
              className="map"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
              style={{ borderRadius: "1rem" }}
              src="https://www.google.com/maps/embed/v1/place?q=Beverly+Hills+Public+School,+King+Georges+Road,+Beverly+Hills+NSW,+Australia&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
            ></iframe>
          </div>
        </>
      );
    } else if (info === "mand") {
      return (
        <>
          <div className="info">
            <div style={{ height: "3rem", width: "100%", backgroundColor: "var(--secondary-color)" }}></div>

            <div
              className="services-banner"
              style={{ backgroundPosition: "50% 50%", backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/images/banners/mm_service_banner.jpg)" }}
            >
              <p className="services-banner-text">{t("services.b_banner_text")}</p>
              <p className="services-banner-subtext" style={{ paddingBottom: "2rem" }}>
                {t("services.b_banner_subtext")}
              </p>
            </div>
            <div className="service-info-text">
              <Trans i18nKey="services.b_info" />
            </div>
            <p style={{ padding: "0.5rem", textAlign: "center" }}>
              <Trans i18nKey="services.b_text">
                <Link to={"/join-us/sunday-school"}></Link>
              </Trans>
            </p>
            <iframe
              className="map"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
              style={{ borderRadius: "1rem" }}
              src="https://www.google.com/maps/embed/v1/place?q=Beverly+Hills+Chinese+Baptist+Church,+Stoney+Creek+Road,+Beverly+Hills+NSW,+Australia&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
            ></iframe>
          </div>
        </>
      );
    } else if (info === "cant") {
      return (
        <>
          <div className="info">
            <div style={{ height: "3rem", width: "100%", backgroundColor: "var(--tertiary-color)" }}></div>

            <div
              className="services-banner"
              style={{ backgroundPosition: "50% 60%", backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/images/banners/services_banner.jpg)" }}
            >
              <p className="services-banner-text">{t("services.c_banner_text")}</p>
              <p className="services-banner-subtext" style={{ paddingBottom: "2rem" }}>
                {t("services.c_banner_subtext")}
              </p>
            </div>
            <div className="service-info-text">
              <Trans i18nKey="services.c_info" />
            </div>
            <p style={{ padding: "0.5rem", textAlign: "center" }}>
              <Trans i18nKey="services.c_text">
                <Link to={"/join-us/sunday-school"}></Link>
              </Trans>
            </p>
            <iframe
              className="map"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
              style={{ borderRadius: "1rem" }}
              src="https://www.google.com/maps/embed/v1/place?q=Beverly+Hills+Chinese+Baptist+Church,+Stoney+Creek+Road,+Beverly+Hills+NSW,+Australia&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
            ></iframe>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  }

  return (
    <>
      <div className="section" style={{ borderBottom: "0px", marginTop: "2rem" }}>
        <div className="card-container">
          <div className="card">
            <div className="circle" style={{ backgroundColor: "var(--primary-color)" }}></div>
            <p className="service-title">{t("services.d_heading")}</p>
            <p style={{ textAlign: "center" }}>
              <Trans i18nKey="services.d_text" />
            </p>
            <button
              type="button"
              className="button"
              onClick={() => {
                setInfo("eng");
              }}
            >
              <span class="material-symbols-outlined">info</span>
              {t("services.info_button")}
            </button>
          </div>
          <div className="card">
            <div className="circle" style={{ backgroundColor: "var(--secondary-color)" }}></div>
            <p className="service-title">{t("services.e_heading")}</p>
            <p style={{ textAlign: "center" }}>
              <Trans i18nKey="services.e_text"></Trans>
            </p>
            <button
              type="button"
              className="button"
              onClick={() => {
                setInfo("mand");
              }}
            >
              <span class="material-symbols-outlined">info</span>
              {t("services.info_button")}
            </button>
          </div>
          <div className="card">
            <div className="circle" style={{ backgroundColor: "var(--tertiary-color)" }}></div>
            <p className="service-title">{t("services.f_heading")}</p>
            <p style={{ textAlign: "center" }}>
              <Trans i18nKey="services.f_text" />
            </p>
            <button
              type="button"
              className="button"
              onClick={() => {
                setInfo("cant");
              }}
            >
              <span class="material-symbols-outlined">info</span>
              {t("services.info_button")}
            </button>
          </div>
        </div>
      </div>
      <div className="section info-section" style={{ borderBottom: "0px" }}>
        {infoSection()}
      </div>
    </>
  );
}
