import { Link } from "react-router-dom";
import React from "react";
import { useTranslation, Trans } from "react-i18next";

const engGroupsEng = {
  Rewire: { "age group": "High schoolers", time: "7:30pm, Friday (Except School Holidays)", link: "/join-us/rewire-youth-group" },
  Converge: { "age group": "Young adults", time: "8:00pm, Wednesday (Except School Holidays)", link: "" },
};

// const cantGroups = {
//   "bethel fellowship": { "age group": "seniors", time: "10:00am, 2nd & 4th Thursday", link: "" },
//   "cana fellowship": { "age group": "adults", time: "2:30pm, 1st Sunday", link: "" },
//   "canaan fellowship": { "age group": "adults", time: "8:00pm, 2nd & 4th Friday", link: "" },
//   "canaan fellowship": { "age group": "adults", time: "4:00pm, 2nd & 4th Saturday", link: "" },
//   "carmel fellowship": { "age group": "adults", time: "10:00am, 1st & 3rd Thursday", link: "" },
//   "elisha fellowship": { "age group": "young adults", time: "2:30pm, 1st Sunday", link: "" },
//   "enoch fellowship": { "age group": "adults", time: "3:00pm, 3rd Saturday", link: "" },
//   "joseph fellowship": { "age group": "adults", time: "2:30pm, 2nd Saturday", link: "" },
//   "joshua fellowship": { "age group": "adults", time: "2:00pm, 3rd Sunday", link: "" },
//   "moses fellowship": { "age group": "young couples", time: "2:00pm, 4th Sunday", link: "" },
//   "opera fellowship": { "age group": "adults", time: "2:30pm, 2nd Sunday", link: "" },
//   "peter fellowship": { "age group": "adults", time: "2:30pm, 3nd Sunday", link: "" },
//   "timothy fellowship": { "age group": "young couples", time: "2:30pm, 4th Sunday", link: "" },
// };

const mandGroupsEng = {
  "Joy Fellowship": { "age group": "Young couples", time: "7:45pm, 1st & 3rd Friday", link: "" },
  "Mandarin Fellowship": { "age group": "All", time: "5:00pm, 2nd Saturday", link: "" },
  "Ladies Group": { "age group": "Ladies", time: "1:00pm, Tuesday", link: "" },
};

const engGroupsTrad = {
  rewire: { "age group": "中學生", time: "星期五, 晚上7時30分 (學校假期除外)", link: "/join-us/rewire-youth-group" },
  converge: { "age group": "成人", time: "星期三, 晚上8時 (學校假期除外)", link: "" },
};

const mandGroupsTrad = {
  喜樂小組: { "age group": "年輕夫婦", time: "每月第一及第三個星期五, 晚上7時45分", link: "" },
  國語團契: { "age group": "所有人", time: "每月第二個星期六, 下午5時", link: "" },
  姐妹小組: { "age group": "女仕", time: "星期二, 下午1時", link: "" },
};

const engGroupsSimp = {
  Rewire: { "age group": "中学生", time: "星期五, 晚上7时30分 (学校假期除外)", link: "/join-us/rewire-youth-group" },
  Converge: { "age group": "成人", time: "星期三, 晚上8时 (学校假期除外)", link: "" },
};

const mandGroupsSimp = {
  喜乐小组: { "age group": "年轻夫妇", time: "每月第一及第三个星期五, 晚上7时45分", link: "" },
  国语团契: { "age group": "所有人", time: "每月第二个星期六, 下午5时", link: "" },
  姐妹小组: { "age group": "女仕", time: "星期二, 下午1时", link: "" },
};

export default function FellowshipGroups() {
  const { t, i18n } = useTranslation();

  let engGroups = "";
  let mandGroups = "";

  if (window.localStorage.getItem("i18nextLng").includes("en")) {
    engGroups = engGroupsEng;
    mandGroups = mandGroupsEng;
  } else if (window.localStorage.getItem("i18nextLng").includes("trad")) {
    engGroups = engGroupsTrad;
    mandGroups = mandGroupsTrad;
  } else if (window.localStorage.getItem("i18nextLng").includes("simp")) {
    engGroups = engGroupsSimp;
    mandGroups = mandGroupsSimp;
  }
  const Table = (groups) => {
    const [isMobile, setMobile] = React.useState(window.innerWidth <= 650);

    const updateMedia = () => {
      setMobile(window.innerWidth <= 650);
    };

    React.useEffect(() => {
      window.addEventListener("resize", updateMedia);
      return () => window.removeEventListener("resize", updateMedia);
    });

    return <div>{isMobile ? GroupsTableMobile(groups) : GroupsTable(groups)}</div>;
  };

  function GroupsTable(groups) {
    const map = Object.keys(groups).map((name, i) => {
      return (
        <tr className="table-tr">
          {groups[name]["link"].length > 0 ? (
            <td className="table-td" style={{ textAlign: "center", fontFamily: "WMFD-ExtraBold", color: "var(--secondary-color)", borderRight: "1px solid #b9b9b9" }}>
              {name}
              <Link style={{ marginLeft: "0.5rem" }} className="button" to={groups[name]["link"]}>
                <span class="material-symbols-outlined">info</span>
              </Link>
            </td>
          ) : (
            <td className="table-td" style={{ textAlign: "center", fontFamily: "WMFD-ExtraBold", color: "var(--secondary-color)", borderRight: "1px solid #b9b9b9" }}>
              {name}
            </td>
          )}

          <td className="table-td" style={{ borderRight: "1px solid #b9b9b9" }}>
            {groups[name]["age group"]}
          </td>
          <td className="table-td">{groups[name]["time"]}</td>
        </tr>
      );
    });

    return map;
  }

  function GroupsTableMobile(groups) {
    const map = Object.keys(groups).map((name, i) => {
      return (
        <>
          {groups[name]["link"].length > 0 ? (
            <tr className="table-td table-td-bold">
              {name}
              <Link style={{ marginLeft: "0.5rem" }} className="button" to={groups[name]["link"]}>
                <span class="material-symbols-outlined">info</span>
              </Link>
            </tr>
          ) : (
            <tr className="table-td table-td-bold">{name}</tr>
          )}
          <tr className="table-td" style={{ textAlign: "center" }}>
            {groups[name]["age group"]}
          </tr>
          <tr className="table-td" style={{ textAlign: "center", borderBottom: "1px solid #b9b9b9" }}>
            {groups[name]["time"]}
          </tr>
        </>
      );
    });

    return map;
  }
  return (
    <div className="container">
      <div className="banner" style={{ backgroundPosition: "center", backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(/images/banners/fellowship_groups_banner.jpg)" }}>
        <p className="banner-text">{t("fellowship.banner_text")}</p>
        <p className="banner-subtext">{t("fellowship.banner_subtext")}</p>
      </div>
      <div className="section">
        <h3>
          <Trans i18nKey="fellowship.description" />
        </h3>
      </div>
      <div className="section" style={{ paddingBottom: "2rem", display: "flex", flexDirection: "column", alignItems: "center", gap: "2rem" }}>
        <h1 style={{ width: "100%" }}></h1>
        <div
          style={{
            padding: "1rem",
            borderRadius: "3rem",
            backgroundColor: "var(--primary-color)",
            color: "white",
            textAlign: "center",
            fontSize: "1.5rem",
            fontFamily: "WMFD-SemiBold",
            width: "100%",
          }}
        >
          {t("fellowship.a_heading")}
        </div>
        <table
          style={{
            width: "100%",
          }}
          className="table"
        >
          {Table(engGroups)}
        </table>
        <div
          style={{
            padding: "1rem",
            borderRadius: "3rem",
            backgroundColor: "var(--secondary-color)",
            color: "white",
            textAlign: "center",
            fontSize: "1.5rem",
            fontFamily: "WMFD-SemiBold",
            width: "100%",
          }}
        >
          {t("fellowship.b_heading")}
        </div>
        <table
          style={{
            width: "100%",
          }}
          className="table"
        >
          {Table(mandGroups)}
        </table>
        <div
          style={{
            padding: "1rem",
            borderRadius: "3rem",
            backgroundColor: "var(--tertiary-color)",
            color: "white",
            textAlign: "center",
            fontSize: "1.5rem",
            fontFamily: "WMFD-SemiBold",
            width: "100%",
          }}
        >
          {t("fellowship.c_heading")}
        </div>
        {/* <table
          style={{
            width: "100%",
          }}
          className="table"
        >
          {Table(cantGroups)}
        </table> */}
        <h3>
          {t("fellowship.c_text")}
          <br />
          <br />
          <Trans i18nKey="fellowship.d_text">
            <Link to={"/find-us/contacts"}></Link>
          </Trans>
        </h3>
      </div>
    </div>
  );
}
