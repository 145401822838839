import React from "react";
import { useTranslation, Trans } from "react-i18next";

const beliefsEng = {
  "THE GODHEAD":
    "There is one God Who is eternal personal Spirit. He is infinite in power, wisdom, holiness and love. He is Triune in essential being and revealed to us as Father, Son and Holy Spirit.",
  "THE SCRIPTURES":
    "The divine inspiration and supreme authority of the Scriptures consisting the 66 books of the Old and New Testaments, which are the infallible Word of God to us, written by human authors under the supernatural guidance of the Holy Spirit.",
  "THE DEITY AND HUMANITY OF CHRIST": "The incarnation and virgin birth of the Lord Jesus Christ who is God the Son, and who in one Person is truly God and truly man.",
  "THE SINFULNESS OF MAN":
    "Man was made in the image of God and for fellowship with Him. By transgression of God’s command he fell from fellowship with God and his nature was corrupted. As a consequence, all men are spiritually dead under Satan’s dominion and control and subject to God’s wrath and condemnation. Therefore, apart from God’s grace, man is helpless and hopeless.",
  "ATONEMENT FOR SIN":
    "In order to redeem mankind from the guilt, penalty and power of sin, Jesus Christ became man and died a sacrificial death as our representative substitute. By His resurrection, God’s acceptance of His atoning death was demonstrated. This atonement is sufficient for the whole world but effective only in those who receive it. The sinner is justified and reconciled to God, not through any personal merit but solely on the basis of God’s gracious gift of salvation in Jesus Christ received through faith.",
  "CHRIST’S RESURRECTION": "The bodily resurrection of the Lord Jesus Christ from the dead and His ascension into heaven.",
  SALVATION:
    "The ministry of the Holy Spirit is necessary for the acceptance of God’s provision of salvation. The Holy Spirit convinces sinners of their sinfulness, leads them to personal faith in Jesus Christ as Lord and Saviour and so brings them to spiritual birth as God’s children and to fellowship in Christ. Working within the life of believers the Holy Spirit makes real the presence of Christ, witnesses to their relationship with God, leads into all truth, bestows gifts for effective service and produces graces for holy living. ",
  "REWARDS AND PUNISHMENTS":
    "God has appointed a day of final judgment for the world. At that time Jesus Christ will judge every man and each will receive reward or punishment according to his deeds. Those judged righteous, in their resurrected and glorified bodies, will receive their reward and will dwell forever in Heaven with the Lord. The unrighteous will be consigned to Hell, the place of everlasting punishment.",
  "THE WORK OF THE HOLY SPIRIT":
    "The direct work of the Holy Spirit is in the regeneration of persons, in their sanctification and in their preservation to the Heavenly Kingdom of the Lord Jesus Christ.",
  CHURCH:
    "The Church is the body of people whom God has separated from the world through faith in Jesus Christ as their Lord and Saviour. All regenerate persons are members of the universal Church of God which takes local form wherever groups of believers unite for worship, fellowship and service in accordance with scriptural principles. All believers are called to a priestly ministry in the offering of spiritual sacrifices and sent into the world to be witnesses. God calls individuals to positions of oversight and leadership or to special ministries. The Church recognizes such by ordaining pastors, commissioning missionaries, appointing deacons and other leaders, following New Testament practice.",
  "THE RETURN OF THE LORD JESUS CHRIST": "The future personal return of the Lord Jesus Christ.",
  "THE ORDINANCES OF THE LORD JESUS CHRIST FOR BELIEVERS, NAMELY BAPTISM BY IMMERSION AND THE LORD’S SUPPER": "",
};

const beliefsTrad = {
  神的獨一性: "獨一真神是永在的靈，衪有無限的權力、智慧、聖潔和慈愛。祂是三位一體，以聖父、聖子和聖靈向我們顯示。",
  聖經: "聖經具有神聖的靈感和無上權威。新舊約全書共有六十六卷，都是上帝給我們無誤的話語，由聖靈超自然引導個别作者寫成。",
  基督的神性和人性: "道成肉身由童貞女所生的主耶穌基督是神的兒子。祂是唯一真正的神亦是真正的人。",
  人的罪性:
    "人是照神的形象被造，並且和神有團契相交關係。由於違背神的命令，人與神在融洽關係中墮落。人性變成敗壞，結果受撒但的管轄、控制，加上神的憤怒、定罪，人的靈命因而死亡。因此若無神的救恩，人是無助和無希望。",
  贖罪: "為了將人類從罪惡、刑罰和罪的權勢中贖回，耶穌基督成為人的樣式，犧牲自己代替我們的刑罰而死。祂的復活顯明上帝接納祂的死為贖罪祭。\r\n\r\n衪一次贖罪代替了世上所有相信祂的人。罪人被稱為義與神和好，不是靠好行為，而是因信耶穌基督的救贖獲得神的恩典為禮物。\r\n",
  基督復活: "主耶穌基督的肉身從死裡復活和祂的升天返回天家。",
  拯救: "獲得神的救恩必須有聖靈動工。聖靈使罪人確知他們的罪性而引領他們相信耶穌基督是他們的主和拯救者，使他們重生成為神的兒女與基督有團契相交關係。聖靈在信徒心中動工，感受到基督的同在，並且印証他們與神的關係，引導他們進入真理得到恩賜作主工，藉著恩典過聖潔的生活。",
  賞賜和刑罰:
    "神定了對世人最後審判日，屆時耶穌基督將審判每一個人，根據那人的行為或賞賜或刑罰。被判為正義的，他們復活榮耀的身體將會獲得賞賜，並且永遠在天國與主同在。那些不義的人將被交付到地獄，這是永刑的地方。",
  聖靈的工作: "聖靈直接的工作是使人們有新生，使他們成聖和保守他們進入主耶穌基督的天國。",
  教會: "教會是神已經從世界分別出來相信耶穌基督是他們的主宰和救贖主的群體。\n\n所有重生的人都是神普世教會的會員。他們以地方教會的模式同心組合依照聖經的原則敬拜、團契相交和事奉。所有信徒都被呼召作祭司，獻上自己作靈祭，被差往世界為主作見證。神呼召個別信徒擔任監督和領導職位，或擔任特別事工。教會按照新約的做法按立牧師，差遣宣教士，委任執事和其他領袖，以此確認他們的職位。\n",
  主耶穌基督再降臨: "主耶穌基督將來再親自降臨。",
  "主耶穌基督為信徒設立的聖禮，就是浸禮和聖餐。": "",
};

const beliefsSimp = {
  神的独一性: "独一真神是永在的灵，衪有无限的权力、智慧、圣洁和慈爱。祂是三位一体，以圣父、圣子和圣灵向我们显示。",
  圣经: "圣经具有神圣的灵感和无上权威。新旧约全书共有六十六卷，都是上帝给我们无误的话语，由圣灵超自然引导个别作者写成。",
  基督的神性和人性: "道成肉身由童贞女所生的主耶稣基督是神的儿子。祂是唯一真正的神亦是真正的人。",
  人的罪性:
    "人是照神的形象被造，并且和神有团契相交关系。由于违背神的命令，人与神在融洽关系中堕落。人性变成败坏，结果受撒但的管辖、控制，加上神的愤怒、定罪，人的灵命因而死亡。因此若无神的救恩，人是无助和无希望。",
  赎罪: "为了将人类从罪恶、刑罚和罪的权势中赎回，耶稣基督成为人的样式，牺牲自己代替我们的刑罚而死。祂的复活显明上帝接纳祂的死为赎罪祭。\r\n\r\n衪一次赎罪代替了世上所有相信祂的人。罪人被称为义与神和好，不是靠好行为，而是因信耶稣基督的救赎获得神的恩典为礼物。\r\n",
  基督复活: "主耶稣基督的肉身从死里复活和祂的升天返回天家。",
  拯救: "获得神的救恩必须有圣灵动工。圣灵使罪人确知他们的罪性而引领他们相信耶稣基督是他们的主和拯救者，使他们重生成为神的儿女与基督有团契相交关系。圣灵在信徒心中动工，感受到基督的同在，并且印证他们与神的关系，引导他们进入真理得到恩赐作主工，借着恩典过圣洁的生活。",
  赏赐和刑罚:
    "神定了对世人最后审判日，届时耶稣基督将审判每一个人，根据那人的行为或赏赐或刑罚。被判为正义的，他们复活荣耀的身体将会获得赏赐，并且永远在天国与主同在。那些不义的人将被交付到地狱，这是永刑的地方。",
  圣灵的工作: "圣灵直接的工作是使人们有新生，使他们成圣和保守他们进入主耶稣基督的天国。",
  教会: "教会是神已经从世界分别出来相信耶稣基督是他们的主宰和救赎主的群体。\n\n所有重生的人都是神普世教会的会员。他们以地方教会的模式同心组合依照圣经的原则敬拜、团契相交和事奉。所有信徒都被呼召作祭司，献上自己作灵祭，被差往世界为主作见证。神呼召个别信徒担任监督和领导职位，或担任特别事工。教会按照新约的做法按立牧师，差遣宣教士，委任执事和其他领袖，以此确认他们的职位。\n",
  主耶稣基督再降临: "主耶稣基督将来再亲自降临。",
  "主耶稣基督为信徒设立的圣礼，就是浸礼和圣餐。": "",
};

export default function Beliefs() {
  const { t, i18n } = useTranslation();
  let beliefs = {};

  if (window.localStorage.getItem("i18nextLng").includes("en")) {
    beliefs = beliefsEng;
  } else if (window.localStorage.getItem("i18nextLng").includes("trad")) {
    beliefs = beliefsTrad;
  } else if (window.localStorage.getItem("i18nextLng").includes("simp")) {
    beliefs = beliefsSimp;
  }

  // Switches table layout for desktop vs mobile
  const Table = () => {
    const [isMobile, setMobile] = React.useState(window.innerWidth <= 650);

    const updateMedia = () => {
      setMobile(window.innerWidth <= 650);
    };

    React.useEffect(() => {
      window.addEventListener("resize", updateMedia);
      return () => window.removeEventListener("resize", updateMedia);
    });

    return <div>{isMobile ? BeliefsMobile() : Beliefs()}</div>;
  };

  function Beliefs() {
    const map = Object.keys(beliefs).map((belief, i) => {
      return (
        <tr className="table-tr">
          {/* <td className="table-td" style={{ borderRight: "1px solid #b9b9b9" }}> */}
          <td className="table-td">
            <div className="table-td-circle">{i + 1}</div>
          </td>
          <td style={{ padding: "1rem", flex: 12 }}>
            <p style={{ fontFamily: "WMFD-Bold", marginBottom: "0.5rem", color: "var(--primary-color)" }}>{belief}</p>
            <p>{beliefs[belief]}</p>
          </td>
        </tr>
      );
    });

    return map;
  }

  function BeliefsMobile() {
    const map = Object.keys(beliefs).map((belief, i) => {
      return (
        <>
          {/* <tr className="table-td" style={{ borderBottom: "1px solid #b9b9b9" }}> */}
          <tr className="table-td">
            <div className="table-td-circle">{i + 1}</div>
          </tr>
          <tr className="table-tr" style={{ borderBottom: "0px" }}>
            <p style={{ textAlign: "center", fontFamily: "WMFD-Bold", color: "var(--primary-color)" }}>{belief}</p>
          </tr>
          <tr className="table-tr">
            <p>{beliefs[belief]}</p>
          </tr>
        </>
      );
    });

    return map;
  }
  return (
    <div className="container">
      <div className="banner" style={{ backgroundPosition: "50% 50%", backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/images/banners/our_beliefs_banner.jpg)" }}>
        <p className="banner-subtext">{t("beliefs.banner_subtext")}</p>
        <p className="banner-text">{t("beliefs.banner_text")}</p>
      </div>
      <div className="section" style={{ marginTop: "2rem", paddingBottom: "1rem" }}>
        <table className="table">{Table()}</table>
        <h1></h1>
        <div>
          <p style={{ textAlign: "center" }}>
            <Trans i18nKey="beliefs.a_text">
              <a href="https://nswactbaptists.org.au/beliefs/"></a>
            </Trans>
          </p>
        </div>
      </div>
    </div>
  );
}
