import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";

export default function CommunityOutreach() {
  const { t, i18n } = useTranslation();
  return (
    <div className="container">
      <div className="banner" style={{ backgroundPosition: "50% 70%", backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/images/banners/about_our_church_banner.jpg)" }}>
        <p className="banner-text">{t("ps.banner_text")}</p>
        <p className="banner-subtext">{t("ps.banner_subtext")}</p>
      </div>

      <div className="section">
        <h1> {t("ps.a_heading")}</h1>
        <h3>{t("ps.b_heading")}</h3>
        <p>
          <Trans i18nKey="ps.b_text"></Trans>
        </p>
        <h3>{t("ps.c_heading")}</h3>
        <p>
          <Trans i18nKey="ps.c_text"></Trans>
        </p>
        <h3>{t("ps.d_heading")}</h3>
        <p>
          <Trans i18nKey="ps.d_text">
            <Link to={"/find-us/contacts"} />
          </Trans>
        </p>
        <h3>{t("ps.e_heading")}</h3>
        <p>{t("ps.e_text")}</p>
      </div>
    </div>
  );
}
