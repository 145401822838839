import logo from "../logo.jpg";
import "./Home.css";
import ContactForm from "../components/ContactForm";
import ServicesInfo from "../components/ServicesInfo";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import React from "react";
import "./Carousel.css";
import { useTranslation, Trans } from "react-i18next";

const responsive = {
  desktop: {
    breakpoint: { max: 4000, min: 1200 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1000, min: 425 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 465, min: 0 },
    items: 1,
  },
};

/* ensure all images are 4:3 */
const homeCarouselImages = {
  1: "/images/home_carousel/hc_1.jpg",
  2: "/images/home_carousel/hc_2.jpg",
  3: "/images/home_carousel/hc_3.jpg",
  4: "/images/home_carousel/hc_4.jpg",
  5: "/images/home_carousel/hc_5.jpg",
  6: "/images/home_carousel/hc_6.jpg",
};

export default function Home({ language }) {
  const [t, i18n] = useTranslation();

  React.useEffect(() => {
    if (language === "trad") {
      i18n.changeLanguage("trad");
    } else if (language === "simp") {
      i18n.changeLanguage("simp");
    } else if (language === "en") {
      i18n.changeLanguage("en");
    }
  }, []);

  const HomeBanner = () => {
    const [isMobile, setMobile] = React.useState(window.innerWidth <= 1000);

    const updateMedia = () => {
      setMobile(window.innerWidth <= 1000);
    };

    React.useEffect(() => {
      window.addEventListener("resize", updateMedia);
      return () => window.removeEventListener("resize", updateMedia);
    });

    return <>{isMobile ? HomeCarousel() : HomeVideo()}</>;
  };

  function HomeCarousel() {
    return (
      <>
        <Carousel
          swipeable={false}
          draggable={false}
          showDots={false}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={5000}
          keyBoardControl={true}
          customTransition="transform .8s ease-in-out"
          transitionDuration={800}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          dotListClass="custom-dot-list-style"
        >
          {Object.keys(homeCarouselImages).map((k, i) => {
            return (
              <>
                <div className="carousel-image-container">
                  <img src={homeCarouselImages[k]} className="carousel-image-full"></img>
                </div>
              </>
            );
          })}
          <div className="carousel-image-container">
            <img src="/images/home_carousel/hc_1.jpg" className="carousel-image-full"></img>
          </div>
        </Carousel>
        <div className="video-text">{t("home.video_text")}</div>
      </>
    );
  }

  function HomeVideo() {
    return (
      <>
        <div className="video-wrapper">
          <iframe
            className="home-banner"
            // src="https://www.youtube.com/embed/MJshhpFdDNw?loop=1&autoplay=1&mute=1&controls=0&modestbranding=1&playlist=PL52beoep_skrxnQyGoRcnyaMN4PCMHhNG"
            src="https://www.youtube.com/embed/?loop=1&autoplay=1&mute=1&controls=0&rel=0&modestbranding=1&listType=playlist&list=PL52beoep_skrxnQyGoRcnyaMN4PCMHhNG"
            title="YouTube video player"
            frameBorder="0"
            // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen="false"
          ></iframe>
        </div>
        <div className="video-text">{t("home.video_text")}</div>
      </>
    );
  }

  return (
    <>
      <div className="container home-container" style={{ paddingBottom: "0rem" }}>
        {HomeBanner()}
        <div className="section" style={{ marginBottom: "2rem" }}>
          <h1> {t("aoc.a_heading")}</h1>
          <h3> {t("aoc.a_text")}</h3>
        </div>
        <div className="home-section">
          <div className="home-row">
            <div className="image-home-container">
              <img src="/images/church_camp.jpg" className="image-default-large"></img>
            </div>
          </div>
          <div className="home-row">
            <h1 style={{ margin: "0px" }}>{t("home.a_heading")}</h1>
            <p style={{ fontFamily: "WMFD-SemiBold" }}>
              <Trans i18nKey="home.a_description" />
            </p>
            <Link className="button" to={"/about-us/about-our-church"}>
              {t("home.a_button")}
            </Link>
          </div>
        </div>

        <div className="home-section reverse">
          <div className="home-row">
            <div className="image-home-container">
              <img src="/images/banners/services_banner.jpg" className="image-default-large"></img>
            </div>
          </div>
          <div className="home-row">
            <h1 style={{ margin: "0px" }}>{t("home.b_heading")}</h1>
            <p style={{ fontFamily: "WMFD-SemiBold" }}>{t("home.b_description")}</p>
            <Link className="button" to={"/join-us/service-times"}>
              {t("home.b_button")}
            </Link>
          </div>
        </div>

        <div className="home-section">
          <div className="home-row">
            <div className="image-home-container">
              <img src="/images/banners/rewire_youth_group_banner.jpg" className="image-default-large"></img>
            </div>
          </div>
          <div className="home-row">
            <h1 style={{ margin: "0px" }}>{t("home.c_heading")}</h1>
            <p style={{ fontFamily: "WMFD-SemiBold" }}>
              {t("home.c_description")}
              <ul style={{ fontFamily: "WMFD-Regular", textAlign: "left" }}>
                <li>
                  <Link style={{ fontFamily: "WMFD-SemiBold" }} to={"/join-us/koala-playgroup"}>
                    {t("home.c_list1a")}
                  </Link>{" "}
                  {t("home.c_list1b")}
                </li>
                <li>
                  <Link style={{ fontFamily: "WMFD-SemiBold" }} to={"/join-us/sunday-school"}>
                    {t("home.c_list2a")}
                  </Link>{" "}
                  {t("home.c_list2b")}
                </li>
                <li>
                  <Link style={{ fontFamily: "WMFD-SemiBold" }} to={"/join-us/rewire-youth-group"}>
                    {t("home.c_list3a")}
                  </Link>{" "}
                  {t("home.c_list3b")}
                </li>
                <li>
                  <Link style={{ fontFamily: "WMFD-SemiBold" }} to={"/join-us/sunday-school"}>
                    {t("home.c_list4a")}
                  </Link>{" "}
                  {t("home.c_list4b")}
                </li>
                <li>
                  <Link style={{ fontFamily: "WMFD-SemiBold" }} to={"/join-us/fellowship-groups"}>
                    {t("home.c_list5a")}
                  </Link>{" "}
                  {t("home.c_list5b")}
                </li>
              </ul>
            </p>
          </div>
        </div>

        <div className="home-section reverse">
          <div className="home-row">
            <div className="image-home-container">
              <img src="images/banners/training_banner.jpg" className="image-default-large"></img>
            </div>
          </div>
          <div className="home-row">
            <h1 style={{ margin: "0px" }}>{t("home.d_heading")}</h1>
            <p style={{ fontFamily: "WMFD-SemiBold" }}>
              {t("home.d_description")}
              <ul style={{ fontFamily: "WMFD-Regular", textAlign: "left" }}>
                <li>
                  <Link style={{ fontFamily: "WMFD-SemiBold" }} to={"/ministries/community-outreach"}>
                    {t("home.d_list1a")}
                  </Link>{" "}
                  {t("home.d_list1b")}
                </li>
                <li>
                  <Link style={{ fontFamily: "WMFD-SemiBold" }} to={"/ministries/training"}>
                    {t("home.d_list2a")}
                  </Link>{" "}
                  {t("home.d_list2b")}
                </li>
                <li>
                  <Link style={{ fontFamily: "WMFD-SemiBold" }} to={"/ministries/mission"}>
                    {t("home.d_list3a")}
                  </Link>{" "}
                  {t("home.d_list3b")}
                </li>
              </ul>
            </p>
          </div>
        </div>

        <div className="section">
          <h1>{t("home.e_heading")}</h1>
          <h3 style={{ textAlign: "center" }}>{t("home.e_description")}</h3>
        </div>
        <div id="map">
          <iframe
            className="map-iframe"
            height="500px"
            frameBorder="0"
            style={{ borderRadius: "1rem" }}
            src="https://www.google.com/maps/embed/v1/place?q=Beverly+Hills+Chinese+Baptist+Church,+Stoney+Creek+Road,+Beverly+Hills+NSW,+Australia&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
          ></iframe>
          <p style={{ fontFamily: "WMFD-Bold", margin: "0 1rem", textAlign: "center" }}>192 Stoney Creek Road, Beverly Hills, NSW 2209</p>
        </div>
        <div className="section">
          <h1>{t("home.f_heading")}</h1>
          <h3>{t("home.f_description")}</h3>
        </div>
        <div className="form-container">
          <ContactForm />
        </div>
      </div>
    </>
  );
}
