import React from "react";
import "./Mission.css";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

const supportTableEng = {
  "Monthly mission prayer meetings": "Currently in Cantonese and Mandarin in alternate months.  Prayerfully, English mission prayer meetings will happen in the near future!",
  "Caring for missionaries":
    "Through encouraging the church to sign Christmas cards for the supported missionaries, save up some money in special money boxes to support them, always pray for them & more!",
  "Interacting with Missionaries": "Whenever their home assignment opportunities arise, church visits are organised to deepen the understanding and relationships between missionaries and the church.",
};

const supportTableTrad = {
  宣教祈禱月會: "現以粵語和國語每月一次交替進行，期望英語宣教祈禱會亦在不久的將來舉行!",
  關心宣教士: "透過鼓勵教會簽聖誕卡給支持的宣教士、為他們儲蓄一些支持的金錢在一個特定的儲錢罐裡、經常為他們禱告的等等關懷支持活動！",
  與宣教士互動: "把握每次宣教士回國述職機會，安排探訪教會以加深宣教士與教會之間的了解和關係。",
};

const supportTableSimp = {
  宣教祈祷月会: "现以粤语和国语每月一次交替进行，期望英语宣教祈祷会亦在不久的将来举行!",
  关心宣教士: "透过鼓励教会签圣诞卡给支持的宣教士、为他们储蓄一些支持的金钱在一个特定的储钱罐里、经常为他们祷告的等等关怀支持活动！",
  与宣教士互动: "把握每次宣教士回国述职机会，安排探访教会以加深宣教士与教会之间的了解和关系。",
};

const localMissions = {
  2012: "Ourimbah, Maitland, Cessnock, Dubbo",
  2013: "Tamworth, Armidale",
  "2014-2019": "Tamworth",
};

const overseasMissionsEng = {
  "Jan 2009": { where: "Heyuan, Xinfeng, Shaoguan, China", partners: "Rev. Dr. Bobby Lo" },
  "Sep 2010": { where: "Ghana, Africa", partners: "CIMA & CIMC" },
  "Dec 2011": { where: "Taichung, Taiwan", partners: "OMF" },
  "Mar 2013": { where: "Sichuan, China", partners: "OMF" },
};

const overseasMissionsTrad = {
  "1/2009": { where: "中國 河源、新豐、韶關", partners: "羅祖澄牧師" },
  "9/2010": { where: "非洲 加納", partners: "CIMA & CIMC" },
  "12/2011": { where: "台中", partners: "OMF" },
  "3/2013": { where: "中國 四川", partners: "OMF" },
};

const overseasMissionsSimp = {
  "1/2009": { where: "中国 河源、新丰、韶关", partners: "罗祖澄牧师" },
  "9/2010": { where: "非洲 加纳", partners: "CIMA & CIMC" },
  "12/2011": { where: "台中", partners: "OMF" },
  "3/2013": { where: "中国 四川", partners: "OMF" },
};

export default function Mission() {
  let supportTable = {};
  let overseasMissions = {};

  if (window.localStorage.getItem("i18nextLng").includes("en")) {
    supportTable = supportTableEng;
    overseasMissions = overseasMissionsEng;
  } else if (window.localStorage.getItem("i18nextLng").includes("trad")) {
    supportTable = supportTableTrad;
    overseasMissions = overseasMissionsTrad;
  } else if (window.localStorage.getItem("i18nextLng").includes("simp")) {
    supportTable = supportTableSimp;
    overseasMissions = overseasMissionsSimp;
  }
  const [isMobile, setMobile] = React.useState(window.innerWidth <= 650);
  const { t, i18n } = useTranslation();

  const updateMedia = () => {
    setMobile(window.innerWidth <= 650);
  };

  React.useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  const Table1 = () => {
    return <div>{isMobile ? SupportTableMobile() : SupportTable()}</div>;
  };

  const Table2 = () => {
    return <div>{isMobile ? LocalMissionsTableMobile() : LocalMissionsTable()}</div>;
  };

  const Table3 = () => {
    return <div>{isMobile ? OverseasMissionsTableMobile() : OverseasMissionsTable()}</div>;
  };

  function SupportTable() {
    const map = Object.keys(supportTable).map((s, i) => {
      return (
        <tr className="table-tr">
          <td className="table-td" style={{ borderRight: "1px solid #b9b9b9" }}>
            <div className="table-td-circle">{i + 1}</div>
          </td>
          <td style={{ padding: "1rem", flex: 12 }}>
            <p style={{ textTransform: "uppercase", fontFamily: "WMFD-Bold", marginBottom: "0.5rem", color: "var(--primary-color)" }}>{s}</p>
            <p>{supportTable[s]}</p>
          </td>
        </tr>
      );
    });

    map.push(
      <tr className="table-tr">
        <td className="table-td" style={{ borderRight: "1px solid #b9b9b9" }}>
          <div className="table-td-circle">4</div>
        </td>
        <td style={{ padding: "1rem", flex: 12 }}>
          <p style={{ textTransform: "uppercase", fontFamily: "WMFD-Bold", marginBottom: "0.5rem", color: "var(--primary-color)" }}>{t("mission.b_table_4_h")}</p>
          <p>
            <Trans i18nKey="mission.b_table_4">
              <Link
                style={{ fontFamily: "WMFD-Bold" }}
                onClick={() => {
                  const section = document.querySelector("#partnerships");
                  console.log(section);
                  section.scrollIntoView();
                }}
              ></Link>
            </Trans>
          </p>
        </td>
      </tr>
    );

    map.push(
      <tr className="table-tr">
        <td className="table-td" style={{ borderRight: "1px solid #b9b9b9" }}>
          <div className="table-td-circle">5</div>
        </td>
        <td style={{ padding: "1rem", flex: 12 }}>
          <p style={{ textTransform: "uppercase", fontFamily: "WMFD-Bold", marginBottom: "0.5rem", color: "var(--primary-color)" }}>{t("mission.b_table_5_h")}</p>
          <p>
            <Trans i18nKey="mission.b_table_5">
              <Link
                style={{ fontFamily: "WMFD-Bold" }}
                onClick={() => {
                  const section = document.querySelector("#local-missions");
                  section.scrollIntoView();
                }}
              ></Link>
              <Link
                style={{ fontFamily: "WMFD-Bold" }}
                onClick={() => {
                  const section = document.querySelector("#overseas-missions");
                  section.scrollIntoView();
                }}
              ></Link>
            </Trans>
          </p>
        </td>
      </tr>
    );

    return map;
  }

  function SupportTableMobile() {
    const map = Object.keys(supportTable).map((s, i) => {
      return (
        <>
          <tr className="table-td" style={{ borderBottom: "1px solid #b9b9b9" }}>
            <div className="table-td-circle">{i + 1}</div>
          </tr>
          <tr className="table-tr">
            <p style={{ textTransform: "uppercase", textAlign: "center", fontFamily: "WMFD-Bold", color: "var(--primary-color)" }}>{s}</p>
          </tr>
          <tr className="table-tr">
            <p>{supportTable[s]}</p>
          </tr>
        </>
      );
    });

    map.push(
      <>
        <tr className="table-td" style={{ borderBottom: "1px solid #b9b9b9" }}>
          <div className="table-td-circle">4</div>
        </tr>
        <tr className="table-td" style={{ borderBottom: "1px solid #b9b9b9" }}>
          <p style={{ textTransform: "uppercase", fontFamily: "WMFD-Bold", marginBottom: "0.5rem", color: "var(--primary-color)" }}>{t("mission.b_table_4_h")}</p>
        </tr>
        <tr className="table-td" style={{ borderBottom: "1px solid #b9b9b9" }}>
          <p>
            <Trans i18nKey="mission.b_table_4">
              <Link
                style={{ fontFamily: "WMFD-Bold" }}
                onClick={() => {
                  const section = document.querySelector("#partnerships");
                  section.scrollIntoView();
                }}
              ></Link>
            </Trans>
          </p>
        </tr>
      </>
    );

    map.push(
      <>
        <tr className="table-td" style={{ borderBottom: "1px solid #b9b9b9" }}>
          <div className="table-td-circle">5</div>
        </tr>
        <tr className="table-td" style={{ borderBottom: "1px solid #b9b9b9" }}>
          <p style={{ textTransform: "uppercase", fontFamily: "WMFD-Bold", marginBottom: "0.5rem", color: "var(--primary-color)" }}>{t("mission.b_table_5_h")}</p>
        </tr>
        <tr className="table-td">
          <p>
            <Trans i18nKey="mission.b_table_5">
              <Link
                style={{ fontFamily: "WMFD-Bold" }}
                onClick={() => {
                  const section = document.querySelector("#local-missions");
                  section.scrollIntoView();
                }}
              ></Link>
              <Link
                style={{ fontFamily: "WMFD-Bold" }}
                onClick={() => {
                  const section = document.querySelector("#overseas-missions");
                  section.scrollIntoView();
                }}
              ></Link>
            </Trans>
          </p>
        </tr>
      </>
    );

    return map;
  }

  function LocalMissionsTable() {
    const map = Object.keys(localMissions).map((year, i) => {
      return (
        <tr className="table-tr">
          <td className="table-td" style={{ borderRight: "1px solid #b9b9b9" }}>
            <div className="table-td-circle">{year}</div>
          </td>
          <td className="table-td" style={{ flex: 4 }}>
            {localMissions[year]}
          </td>
        </tr>
      );
    });
    return map;
  }

  function LocalMissionsTableMobile() {
    const map = Object.keys(localMissions).map((year, i) => {
      return (
        <>
          <tr className="table-td">
            <div className="table-td-circle">{year}</div>
          </tr>
          <tr className="table-td" style={{ textAlign: "center", fontFamily: "WMFT-SemiBoldItalic", borderBottom: "1px solid #b9b9b9" }}>
            {localMissions[year]}
          </tr>
        </>
      );
    });
    return map;
  }

  function OverseasMissionsTable() {
    const map = Object.keys(overseasMissions).map((when, i) => {
      return (
        <tr className="table-tr">
          <td className="table-td" style={{ textAlign: "center", fontFamily: "WMFD-ExtraBold", color: "var(--secondary-color)", borderRight: "1px solid #b9b9b9" }}>
            {when}
          </td>

          <td className="table-td" style={{ textAlign: "center", borderRight: "1px solid #b9b9b9" }}>
            {overseasMissions[when]["where"]}
          </td>
          <td className="table-td" style={{ textAlign: "center" }}>
            {overseasMissions[when]["partners"]}
          </td>
        </tr>
      );
    });

    return map;
  }

  function OverseasMissionsTableMobile() {
    const map = Object.keys(overseasMissions).map((when, i) => {
      return (
        <>
          <tr className="table-td table-td-bold" style={{ borderBottom: "0px" }}>
            {when}
          </tr>

          <tr className="table-td" style={{ textAlign: "center", fontFamily: "WMFT-SemiBoldItalic" }}>
            {overseasMissions[when]["where"]}
          </tr>
          <tr className="table-td" style={{ textAlign: "center", borderBottom: "1px solid #b9b9b9" }}>
            {overseasMissions[when]["partners"]}
          </tr>
        </>
      );
    });

    return map;
  }

  return (
    <div className="container">
      <div className="banner" style={{ backgroundPosition: "50% 50%", backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(/images/banners/mission_banner.jpg)" }}>
        <p className="banner-subtext">{t("mission.banner_subtext")}</p>
        <p className="banner-text">{t("mission.banner_text")}</p>
      </div>

      <div className="section">
        <h1 style={{ width: "100%" }}>{t("mission.a_heading")}</h1>
        <h3>{t("mission.a_text")}</h3>
      </div>

      <div className="section">
        <h1 style={{ width: "100%" }}>{t("mission.b_heading")}</h1>

        <table className="table">{Table1()}</table>
      </div>
      <div className="section">
        <h1>{t("mission.c_heading")}</h1>
        <div className="image-default-container">
          <img className="image-default" src="/images/mission_money_boxes.jpg"></img>
          <h3 style={{ fontFamily: "WMFD-Bold" }}>{t("mission.c_1_heading")}</h3>
          <p>{t("mission.c_1_caption")}</p>
        </div>
        <div className="image-default-container">
          <img className="image-default" src="/images/mission_occ.jpg"></img>
          <h3 style={{ fontFamily: "WMFD-Bold" }}>{t("mission.c_2_heading")}</h3>
          <p>{t("mission.c_2_caption")}</p>
        </div>
        <div className="image-default-container">
          <img className="image-default" src="/images/mission_votm.jpg"></img>
          <h3 style={{ fontFamily: "WMFD-Bold" }}>{t("mission.c_3_heading")}</h3>
          <p>{t("mission.c_3_caption")}</p>
        </div>
      </div>

      <div className="section" id="partnerships">
        <h1 style={{ textTransform: "none" }}>{t("mission.d_heading")}</h1>
        <h3 style={{ fontFamily: "WMFD-Bold" }}>{t("mission.d_1_heading")}</h3>
        <div className="mrow">
          <div className="mcircle">
            <p className="mtitle" style={{ color: "var(--primary-color)" }}>
              {t("mission.m_1_title")}
            </p>
            <p>{t("mission.m_1_description")}</p>
            <p style={{ fontFamily: "WMFT-SemiBoldItalic" }}>{t("mission.m_1_loc")}</p>
            <p>{t("mission.m_1_org")}</p>
          </div>
          <div className="mcircle">
            <p className="mtitle" style={{ color: "var(--tertiary-color)" }}>
              {t("mission.m_2_title")}
            </p>
            <p>{t("mission.m_2_description")} </p>
            <p>{t("mission.m_2_org")}</p>
          </div>
          <div className="mcircle">
            <p className="mtitle" style={{ color: "var(--secondary-color)" }}>
              {t("mission.m_3_title")}
            </p>
            <p>{t("mission.m_3_description")} </p>
            <p>{t("mission.m_3_org")}</p>
          </div>
        </div>
        <div className="mrow">
          <div className="mcircle">
            <p className="mtitle" style={{ color: "var(--secondary-color)" }}>
              {t("mission.m_4_title")}
            </p>
            <p>{t("mission.m_4_description")} </p>
            <p>{t("mission.m_4_org")}</p>
          </div>
          <div className="mcircle">
            <p className="mtitle" style={{ color: "var(--primary-color)" }}>
              {t("mission.m_5_title")}
            </p>
            <p>{t("mission.m_5_description")}</p>
            <p style={{ fontFamily: "WMFT-SemiBoldItalic" }}>{t("mission.m_5_loc")}</p>
            <p>{t("mission.m_5_org")}</p>
          </div>
          <div className="mcircle">
            <p className="mtitle" style={{ color: "var(--tertiary-color)" }}>
              {t("mission.m_6_title")}
            </p>
            <p>{t("mission.m_6_description")}</p>
            <p style={{ fontFamily: "WMFT-SemiBoldItalic" }}>{t("mission.m_6_loc")}</p>
            <p>{t("mission.m_6_org")}</p>
          </div>
        </div>
      </div>
      <div className="section" style={{ width: "100%", backgroundColor: "#E5E5E5" }}>
        <h3 style={{ fontFamily: "WMFD-Bold" }}> {t("mission.d_2_heading")}</h3>
        <div className="arow left-offset">
          <a style={{ borderBottom: "none" }} href="https://www.vom.com.au/">
            <img src="/images/voiceofthematyrs.png" className="aimage" alt="voice of the matyrs logo"></img>
          </a>
          <a style={{ borderBottom: "none" }} href="https://wec.com.au/">
            <img src="/images/wec.jpg" className="aimage" alt="wec logo"></img>
          </a>
        </div>
        <div className="arow right-offset">
          <a style={{ borderBottom: "none" }} href="https://www.cimaustralia.org/">
            <img src="/images/cim.png" className="aimage" alt="chinese international mission logo"></img>
          </a>
          <a style={{ borderBottom: "none" }} href="https://www.restaurantmission.org.au/en">
            <img src="/images/restaurant_mission.png" className="aimage" alt="resturant mission logo"></img>
          </a>
        </div>
      </div>
      <div className="section" id="local-missions">
        <h1> {t("mission.e_heading")}</h1>
        <table className="table">{Table2()}</table>
        <h3>{t("mission.e_1_heading")}</h3>
        <p>
          <Trans i18nKey="mission.e_1_text"></Trans>
        </p>
        <div className="image-default-container">
          <img src="/images/mission_tamworth1.jpg" className="image-default-large" style={{ paddingTop: "1rem", paddingBottom: "1rem" }}></img>
          <p style={{ fontFamily: "WMFD-Bold" }}>{t("mission.e_1_caption")}</p>
        </div>
      </div>
      <div className="section" id="overseas-missions" style={{ paddingBottom: "2rem" }}>
        <h1>{t("mission.e_2_heading")}</h1>
        <table className="table">{Table3()}</table>
      </div>
    </div>
  );
}
