import "./NavbarLink.css";
import React, { useState, useEffect } from "react";

export default function NavbarLink({ title, links }) {
  function generateLinks() {
    const map = Object.keys(links).map((l) => {
      console.log(l);
      return (
        <>
          <a href={links[l]}> {l} </a>
        </>
      );
    });

    return map;
  }

  return (
    <div class="links">
      <a href="/" id="link" style={{ textTransform: "uppercase" }}>
        {title}
      </a>
      <div class="subpage">{generateLinks()}</div>
    </div>
  );
}
