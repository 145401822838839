// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a {
  z-index: 1000;
}

a:hover {
  background-color: var((--primary-color));
  color: black;
}

.links {
  display: flex;
  flex-direction: column;
}

.subpage {
  display: flex;
  flex-direction: row;
}
`, "",{"version":3,"sources":["webpack://./src/components/NavbarLink.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,wCAAwC;EACxC,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB","sourcesContent":["a {\r\n  z-index: 1000;\r\n}\r\n\r\na:hover {\r\n  background-color: var((--primary-color));\r\n  color: black;\r\n}\r\n\r\n.links {\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n\r\n.subpage {\r\n  display: flex;\r\n  flex-direction: row;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
