import { useTranslation, Trans } from "react-i18next";

export default function SocialMedia() {
  const { t, i18n } = useTranslation();
  return (
    <div className="container">
      <div className="banner" style={{ backgroundPosition: "50% 50%", backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(/images/banners/contact_us_banner.jpg)" }}>
        <p className="banner-text">{t("social_media.banner_text")}</p>
        <p className="banner-subtext">{t("social_media.banner_subtext")}</p>
      </div>
      <div className="section"></div>
    </div>
  );
}
