import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Location from "./pages/Location";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import NoMatch from "./pages/NoMatch";
import ChurchHistory from "./pages/ChurchHistory";
import Missionaries from "./pages/Missionaries";
import PastoralTeam from "./pages/PastoralTeam";
import Services from "./pages/Services";
import Beliefs from "./pages/Beliefs";
import AboutOurChurch from "./pages/AboutOurChurch";
import KoalaPlaygroup from "./pages/KoalaPlaygroup";
import Rewire from "./pages/Rewire";
import SpecialNavbar from "./components/SpecialNavbar";
import FellowshipGroups from "./pages/FellowshipGroups";
import ScrollToTop from "./helper/ScrollToTop";
import Mission from "./pages/Mission";
import Contacts from "./pages/Contacts";
import SundaySchool from "./pages/SundaySchool";
import SocialMedia from "./pages/SocialMedia";
import Sermon from "./pages/Sermon";
import Training from "./pages/Training";
import CommunityOutreach from "./pages/CommunityOutreach";
import RoomBooking from "./pages/RoomBooking";
import Events from "./pages/Events";
import Members from "./pages/Members";
import PageTitle from "./helper/PageTitle";

function App() {
  function wrapNavbar(item, title) {
    return (
      <>
        <PageTitle title={`${title} | Beverly Hills Chinese Baptist Church`} />
        <Navbar />
        {item}
      </>
    );
  }

  function wrapSpecialNavbar(item, title) {
    return (
      <>
        <PageTitle title={`${title} | Beverly Hills Chinese Baptist Church`} />
        <SpecialNavbar />
        {item}
      </>
    );
  }
  return (
    <>
      <ScrollToTop /> {/* makes pages scroll to top when a new link within the is clicked*/}
      <Routes>
        <Route path="/" element={wrapSpecialNavbar(<Home language={""} />, "Home")} />
        <Route path="/home" element={wrapSpecialNavbar(<Home language={""} />, "Home")} />
        <Route path="/cm" element={wrapSpecialNavbar(<Home language={"trad"} />, "Home")} />
        <Route path="/mm" element={wrapSpecialNavbar(<Home language={"simp"} />, "Home")} />
        <Route path="/em" element={wrapSpecialNavbar(<Home language={"en"} />, "Home")} />
        <Route path="/about-us">
          <Route path="about-our-church" element={wrapNavbar(<AboutOurChurch />, "About Our Church")} />
          <Route path="doctrinal-beliefs" element={wrapNavbar(<Beliefs />, "Doctrinal Beliefs")} />
          <Route path="church-history" element={wrapNavbar(<ChurchHistory />, "Church History")} />
          <Route path="pastoral-team-and-staff" element={wrapNavbar(<PastoralTeam />, "Pastoral Team and Staff")} />
          <Route path="missionaries-we-support" element={wrapNavbar(<Missionaries />, "Missionaries We Support")} />
        </Route>
        <Route path="/join-us">
          <Route path="service-times" element={wrapNavbar(<Services />, "Service Times")} />
          <Route path="fellowship-groups" element={wrapNavbar(<FellowshipGroups />, "Fellowship Groups")} />
          <Route path="sunday-school" element={wrapNavbar(<SundaySchool />, "Sunday School")} />
          <Route path="koala-playgroup" element={wrapNavbar(<KoalaPlaygroup />, "Koala Playgroup")} />
          <Route path="rewire-youth-group" element={wrapNavbar(<Rewire />, "Rewire Youth Group")} />
        </Route>
        <Route path="/events" element={wrapNavbar(<Events />, "Events")} />
        <Route path="/ministries">
          <Route path="community-outreach" element={wrapNavbar(<CommunityOutreach />, "Community Outreach")} />
          <Route path="training" element={wrapNavbar(<Training />, "Training")} />
          <Route path="mission" element={wrapNavbar(<Mission />, "Mission")} />
        </Route>
        <Route path="/find-us">
          <Route path="location" element={wrapNavbar(<Location />, "Location")} />
          <Route path="social-media" element={wrapNavbar(<SocialMedia />, "Social Media")} />
          <Route path="contacts" element={wrapNavbar(<Contacts />, "Contacts")} />
        </Route>
        <Route path="/resources">
          <Route path="sermons" element={wrapNavbar(<Sermon />, "Sermons")} />
          <Route path="room-booking" element={wrapNavbar(<RoomBooking />, "Room Booking")} />
        </Route>
        <Route path="/members" element={wrapNavbar(<Members />, "Members")} />
        <Route path="*" element={wrapNavbar(<NoMatch />, "Page Does Not Exist")} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
