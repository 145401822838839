import "./Home.css";
import React from "react";
import { useTranslation, Trans } from "react-i18next";

const infoEng = {
  when: "Friday 7:30pm to 9:30pm during regular school terms  (contact us first as we frequently have other arrangements!)",
  where: "Beverly Hills Chinese Baptist Church, 192 Stoney Creek Rd, Beverly Hills 2209",
  for: "All high-school students (Year 7-12)",
  language: "English",
};

const infoTrad = {
  時間: "週五晚上7:30至9:30（學校假期暫停） *請先與我們聯繫，因我們經常有其他安排！",
  地點: "比華利山華人浸信會, 192 Stoney Creek Rd, Beverly Hills 2209",
  對象: "所有中學生（7年級至12年級） ",
  語言: "英語",
};

const infoSimp = {
  时间: "周五晚上7:30至9:30（学校假期暂停） *请先与我们联系，因我们经常有其他安排！",
  地点: "比华利山华人浸信会, 192 Stoney Creek Rd, Beverly Hills 2209",
  对象: "所有中学生（7年级至12年级）",
  语言: "英语",
};

export default function Rewire() {
  const { t, i18n } = useTranslation();

  let info = {};

  if (window.localStorage.getItem("i18nextLng").includes("en")) {
    info = infoEng;
  } else if (window.localStorage.getItem("i18nextLng").includes("trad")) {
    info = infoTrad;
  } else if (window.localStorage.getItem("i18nextLng").includes("simp")) {
    info = infoSimp;
  }

  const Table = () => {
    const [isMobile, setMobile] = React.useState(window.innerWidth <= 650);

    const updateMedia = () => {
      setMobile(window.innerWidth <= 650);
    };

    React.useEffect(() => {
      window.addEventListener("resize", updateMedia);
      return () => window.removeEventListener("resize", updateMedia);
    });

    return <div>{isMobile ? InfoTableMobile() : InfoTable()}</div>;
  };

  function InfoTable() {
    const map = Object.keys(info).map((title, i) => {
      return (
        <tr className="table-tr">
          <td className="table-td-bold">{title}</td>
          <td style={{ padding: "1rem", flex: 4 }}>{info[title]}</td>
        </tr>
      );
    });

    map.push(
      <tr className="table-tr">
        <td className="table-td-bold">{t("rewire.contact")}</td>
        <td style={{ padding: "1rem", flex: 4 }}>
          <Trans i18nKey="rewire.contact_text" />
        </td>
      </tr>
    );

    map.push(
      <tr className="table-tr">
        <td className="table-td-bold">{t("rewire.follow")}</td>
        <td style={{ padding: "1rem", flex: 4 }}>
          Instagram:{" "}
          <a style={{ fontFamily: "WMFD-Bold" }} href="https://www.instagram.com/rewirebhcbc/">
            @rewirebhcbc
          </a>
        </td>
      </tr>
    );

    return map;
  }

  function InfoTableMobile() {
    const map = Object.keys(info).map((title, i) => {
      return (
        <>
          <tr className="table-td-bold" style={{ borderBottom: "0px" }}>
            {title}
          </tr>
          <tr className="table-td" style={{ borderBottom: "1px solid lightgrey" }}>
            {info[title]}
          </tr>
        </>
      );
    });

    map.push(
      <>
        <tr className="table-td-bold" style={{ borderBottom: "0px" }}>
          {t("rewire.contact")}
        </tr>
        <tr className="table-td" style={{ borderBottom: "1px solid lightgrey" }}>
          <Trans i18nKey="rewire.contact_text" />
        </tr>
      </>
    );

    map.push(
      <tr className="table-tr">
        <td className="table-td-bold" style={{ borderBottom: "0px" }}>
          {t("rewire.follow")}
        </td>
        <td className="table-td">
          <a style={{ textDecoration: "underline", fontFamily: "WMFD-Bold", paddingRight: "1rem" }} href="https://www.instagram.com/rewirebhcbc/">
            Instagram
          </a>
          #rewireatbhcbc
        </td>
      </tr>
    );

    return map;
  }

  return (
    <div className="container">
      <div className="banner" style={{ backgroundPosition: "50% 50%", backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/images/banners/rewire_youth_group_banner.jpg)" }}>
        <p className="banner-text">{t("rewire.banner_text")}</p>
        <p className="banner-subtext">{t("rewire.banner_subtext")}</p>
      </div>
      <div className="section">
        <h3 style={{ fontFamily: "WMFD-SemiBold", textAlign: "center" }}>
          <Trans i18nKey="rewire.a_text">
            <span style={{ color: "var(--primary-color)" }} /> 
            <span style={{ color: "var(--secondary-color)" }} />
          </Trans>
        </h3>

        <h1></h1>
        <p style={{ fontFamily: "WMFD-Medium", textAlign: "center" }}>
          <Trans i18nKey="rewire.b_text" />
        </p>
        <h1 style={{ width: "100%" }}></h1>
      </div>
      <div className="section" style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "2rem" }}>
        <table className="table" style={{ marginTop: "2rem" }}>
          {Table()}
        </table>
      </div>
    </div>
  );
}
