// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: radial-gradient(farthest-side, var(--secondary-color) 94%, #0000) top/8px 8px no-repeat, conic-gradient(#0000 30%, var(--secondary-color));
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: l13 1s infinite linear;
}
@keyframes l13 {
  100% {
    transform: rotate(1turn);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Loader.css"],"names":[],"mappings":"AAAA,qCAAqC;AACrC;EACE,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,sJAAsJ;EACtJ,4EAA4E;EAC5E,iCAAiC;AACnC;AACA;EACE;IACE,wBAAwB;EAC1B;AACF","sourcesContent":["/* HTML: <div class=\"loader\"></div> */\r\n.loader {\r\n  width: 50px;\r\n  aspect-ratio: 1;\r\n  border-radius: 50%;\r\n  background: radial-gradient(farthest-side, var(--secondary-color) 94%, #0000) top/8px 8px no-repeat, conic-gradient(#0000 30%, var(--secondary-color));\r\n  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);\r\n  animation: l13 1s infinite linear;\r\n}\r\n@keyframes l13 {\r\n  100% {\r\n    transform: rotate(1turn);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
