import "./EventCard.css";
import { useTranslation, Trans } from "react-i18next";

export default function EventCard({ event }) {
  const { t, i18n } = useTranslation();

  const start = new Date(event["Start"]);
  const end = new Date(event["End"]);

  const imageID = event["Image"].split("/")[5];
  const imageSrc = "https://drive.google.com/thumbnail?id=" + imageID + "&sz=w1000";

  function handleClick() {
    window.open(event["Image"], "_blank");
  }

  function formatDate(date) {
    const daysOfWeekEng = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const daysOfWeekTrad = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
    const daysOfWeekSimp = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];

    let dayOfWeek = "";
    if (i18n.language === "trad") {
      dayOfWeek = daysOfWeekTrad[date.getDay()];
    } else if (i18n.language === "simp") {
      dayOfWeek = daysOfWeekSimp[date.getDay()];
    } else {
      dayOfWeek = daysOfWeekEng[date.getDay()];
    }

    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-indexed, so add 1
    const year = date.getFullYear();

    return `${dayOfWeek}, ${day}/${month}/${year}`;
  }

  function formatTime(date) {
    let hours = date.getHours(); // Get the current hour (0-23)
    const minutes = String(date.getMinutes()).padStart(2, "0"); // Get the current minute (0-59) and pad with zero if needed
    const ampm = hours >= 12 ? "PM" : "AM"; // Determine if it's AM or PM

    hours = hours % 12; // Convert hour to 12-hour format
    hours = hours ? hours : 12; // Adjust 0 to 12 for midnight

    return `${hours}:${minutes} ${ampm}`;
  }

  function LanguageChips() {
    return (
      <div className="language-chip-container">
        {event["English"] && (
          <div className="language-chip" style={{ backgroundColor: "var(--primary-color)" }}>
            {t("events.english")}
          </div>
        )}
        {event["Mandarin"] && (
          <div className="language-chip" style={{ backgroundColor: "var(--secondary-color)" }}>
            {t("events.mandarin")}
          </div>
        )}
        {event["Cantonese"] && (
          <div className="language-chip" style={{ backgroundColor: "var(--tertiary-color)" }}>
            {t("events.cantonese")}
          </div>
        )}
      </div>
    );
  }

  function LinkButton() {
    if (event["Button"] === true) {
      if (i18n.language == "trad") {
        return (
          <a className="button" href={event["Button Link"]}>
            {event["Button Text (Trad)"]}
          </a>
        );
      } else if (i18n.language == "simp") {
        return (
          <a className="button" href={event["Button Link"]}>
            {event["Button Text (Simp)"]}
          </a>
        );
      }
      return (
        <a className="button" href={event["Button Link"]}>
          {event["Button Text (Eng)"]}
        </a>
      );
    }
  }

  return (
    <div className="event-card-section">
      <div className="event-card-row">
        <div className="image-home-container">
          <img
            onClick={() => {
              handleClick();
            }}
            onHover={() => {}}
            className="image-event"
            src={imageSrc}
          ></img>
        </div>
      </div>
      <div className="event-card-row">
        {LanguageChips()}
        <h1 style={{ margin: "0px" }}>{i18n.language == "en" ? event["Name (Eng)"] : i18n.language == "trad" ? event["Name (Trad)"] : event["Name (Simp)"]}</h1>
        <div className="event-card-date-container">
          <h3 style={{ margin: "0rem", fontFamily: "WMFD-SemiBold" }}>
            {formatDate(start)} <br />
          </h3>
          <p>
            {formatTime(start)} - {formatTime(end)}
          </p>
        </div>
        <p> {event["Location"]}</p>

        <p
          style={{ textAlign: "left" }}
          dangerouslySetInnerHTML={{ __html: i18n.language == "en" ? event["Description (Eng)"] : i18n.language == "trad" ? event["Description (Trad)"] : event["Description (Simp)"] }}
        ></p>

        {LinkButton()}
      </div>
    </div>
  );
}
